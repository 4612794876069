.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 400px;
}

.elements {
  width: 400px;
}

.footer {
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  height: calc(var(--spacer) * 9px);
  background-color: var(--backgroundColor);
  padding: 0px calc(var(--spacer) * 1.5px);
  width: calc(100% - (var(--spacer) * 6px));
  border-top: 1px solid var(--pageBackground);
}

.roleRulesContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 560px;
  position: absolute;
  top: 0%;
}
.roleRules {
  width: 65%;
  border-right: 2px solid #f0f2f7;
  padding: 10px 0px 0px 20px;
  box-sizing: border-box;
}

.header {
  color: #000000;
  font-size: 40px;
  line-height: 48px;
  text-align: left;
}

.usersList {
  width: 35%;
  box-sizing: border-box;
  height: 100%;
  padding-left: 8px;
}

.users {
  font-size: 14px;
  line-height: 48px;
}

.rolePopUpCloserDesc {
  margin: 10px 0px 0px 20px;
  font-size: 13px;
  z-index: 99;
}

.innerUserList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  height: 480px;
}

.roleRulesConditions {
  display: flex;
  margin: 10px 0px;
  width: 50%;
  justify-content: flex-end;
  column-gap: 20px;
}

.rolePopupContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  height: 50px;
}

.roleRulesType {
  width: 80px;
}

.roleRulesSelect {
  background-color: #f0f2f7;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.select {
  appearance: none;
  box-sizing: border-box;
  height: var(--inputHeight);
  font-family: var(--fontName);
  font-size: var(--labelFontSize);
  background: var(--inputBackground);
  line-height: var(--labelLineHeight);
  padding-left: calc(var(--spacer) * 1px);
  border-radius: var(--inputBorderRadius);
  border: 1px solid var(--inputBorderColor);
  padding-right: calc(var(--spacer) * 4px);
  background-image: url("data:image/svg+xml;utf8,<svg stroke='black' fill='none' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5'/></svg>");
  background-position-y: calc(var(--spacer) * 1px);
  background-position-x: 100%;
  background-repeat: no-repeat;
}

.permissionsOuterContainer {
  width: 100%;
  margin-left: 2px;
  position: relative;
  display: flex;
  justify-content: center;
}

.permissionsContainer {
  width: 80%;
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  height: calc(500px - var(--spacer) * 9px);
}

.permissionsContainer::-webkit-scrollbar {
  display: none;
}

.submit {
  cursor: pointer;
  border-width: 0;
  border-radius: 2px;
  font-family: var(--fontName);
  height: calc(var(--spacer) * 5px);
  box-shadow: var(--buttonShadowColor);
  margin: 0px calc(var(--spacer) * 1px);
  padding: calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px);
  background-color: var(--primaryColor) !important;
  color: var(--primaryTextColor);
  height: calc(var(--spacer) * 4px);
  box-shadow: var(--buttonShadowColor);
  width: auto;
}

.back {
  cursor: pointer;
  border-width: 0;
  border-radius: 2px;
  width: auto !important;
  font-family: var(--fontName);
  box-shadow: var(--buttonShadowColor);
  color: var(--primaryColor) !important;
  margin: 0px calc(var(--spacer) * 1px);
  background-color: var(--secondaryColor) !important;
  padding: calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px);
  height: calc(var(--spacer) * 4px);
  width: auto;
}

.errorMessage {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: red;
  height: 16px;
  font-size: calc(var(--spacer) * 1.5px);
  line-height: calc(var(--spacer) * 2px);
}

.disabledText {
  color: rgba(0,0,0,0.2);
}
