.afFooter {
    bottom: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--spacer) * 9px);
    padding: 0px calc(var(--spacer) * 1.5px);
    width: calc(100% - (var(--spacer) * 6px));
    border-top: 1px solid var(--pageBackground);
}

.popupTitle {
    margin-top: calc(var(--spacer) * 2px);
}

.formArea {
    display: flex;
    justify-content: center;
    margin: calc(var(--spacer) * 5px) 0px;
}

.column {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.parameterHeader {
    font-size: 3rem;
    text-align: center;
}
.parameterTables{
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }