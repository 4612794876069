.integration-header button {
  color: #0062ff;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: none;
  cursor: pointer;
  height: 40px;
  /* padding: 6px 16px; */
  font-size: 14px;
  min-width: 64px;
  border-radius: 2px !important;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100px;
}

.integrations-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 950px;
  background-color: #f0f2f7;
}

.integrations-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 81%;
  height: 150px;
}

.integrations-header p {
  font-size: 32px;
  margin-bottom: 0px;
}

.integrations-inner-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.integration-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.integration-container {
  /* width: 600px; */
  margin-left: 2px;
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none;
  height: calc(var(--spacer) * 70px);
  border-radius: calc(var(--spacer) * 1px);
  background-color: var(--backgroundColor);
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
}

.integration-container::-webkit-scrollbar {
  display: none;
}

.adapters-container {
  /* width: 600px; */
  background-color: #f0f2f7;
  margin-left: 2px;
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 8px;
}

.adapters-container::-webkit-scrollbar {
  display: none;
}

#h2 {
  font-size: 32px;
}

.integration-table-container {
  height: calc(var(--spacer) * 65px);
  width: 100%;
  overflow: scroll;
}

.integration-component-container {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  padding-right: 20px;
}
.title {
  font-size: 32px;
  font-family: Saira;
  font-weight: normal;
  line-height: 24px;
}

.subtitle {
  color: #676767;
  font-size: 15px;
  font-family: Saira;
  font-weight: normal;
  line-height: 12px;
}

.mainContainer {
  padding-top: 20px;
  background-color: #f0f2f7;
  padding-left: 40px;
  min-height: 100vh;
}