// @import "../node_modules/bootstrap/scss/bootstrap";
@import "./pillirthemevariables";

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: theme-color("coolgray10");
  box-sizing: border-box;
}

h1 {
  font-size: font-size(64px);
  font-weight: $font-weight;
  line-height: ($spacer * 8);
}
h2 {
  font-size: font-size(40px);
  font-weight: $font-weight;
}
h3 {
  font-size: font-size(16px);
  font-weight: $font-weight;
}
h5 {
  $font-weight: 500;
  font-weight: $font-weight;
  font-size: font-size(16px);
}
label {
  font-size: font-size(13px);
}
p {
  font-size: font-size(14px);
}
.btn svg.left {
  margin-right: $spacer;
}
.btn svg.right {
  margin-left: $spacer;
}
button {
  cursor: pointer;
}
button > div > label {
  cursor: pointer;
}

.blue60 {
  background-color: theme-color("blue60") !important;
}
.gray-bg {
  background-color: theme-color("gray60") !important;
}
.green-bg {
  background-color: theme-color("green60") !important;
}
.black-bg {
  background-color: theme-color("black") !important;
}
.white-bg {
  background-color: theme-color("white") !important;
}
.cursor {
  cursor: pointer;
}

/*Colors */
.blue {
  color: theme-color("blue60") !important;
}
.lightblue {
  color: theme-color("blue30") !important;
}
.red {
  color: theme-color("danger") !important;
}
.coolgray {
  color: #8f9299 !important;
}
.gray {
  color: theme-color("gray60") !important;
}
.green {
  color: theme-color("green60") !important;
}
.black {
  color: theme-color("black") !important;
}
.white {
  color: theme-color("white") !important;
}

/* To all menu sidebar header theme */

.sidebar-header {
  background-color: #cdd4e42e;
}
.sidebar-header > label {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: ($spacer * 2);
  height: ($spacer * 5);
  font-size: font-size(13px);
  font-weight: $font-weight;
  line-height: ($spacer * 5);
}

/* For All input text box and area */
.input-mini {
  width: ($spacer * 8) !important;
  height: ($spacer * 4) !important;
}

input,
.form-group input {
  font-size: font-size(13px);
  height: ($spacer * 4);
  width: ($spacer * 49);
  background-color: theme-color("coolgray10");
  border: unset;
  border-radius: 0px;
  padding: $spacer;
  background: unset;
}
.form-group {
  margin-bottom: 0px;
}
.input-outline {
  font-size: font-size(13px);
  height: ($spacer * 4);
  width: ($spacer * 49);
  border-radius: 0px;
  background-color: theme-color("coolgray10") !important;
  border: 1px solid theme-color("notsocoolgray") !important;
  padding: $spacer;
}
textarea,
.form-group textarea {
  font-size: font-size(13px);
  border-radius: 0px;
  height: ($spacer * 10);
  width: ($spacer * 49);
  background-color: theme-color("coolgray10");
  border: unset;
  padding: $spacer;
}
.textarea-outline {
  font-size: font-size(13px);
  height: ($spacer * 10);
  width: ($spacer * 49);
  border-radius: 0px;
  background-color: theme-color("white") !important;
  border: 1px solid theme-color("notsocoolgray") !important;
  padding: $spacer;
}
/* Table structure css */
table {
  font-size: font-size(13px);
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  // background: theme-color("white");
  // box-shadow: 0px -1px 0px #F0F2F7;
  box-shadow: 0px 1px 0px 0px #f5f6fa;
  border-radius: 4px;
}
tr {
  height: ($spacer * 6);
  // @include border('bottom', 1px, #cdd4e466);
}
th {
  padding: $spacer;
}
td {
  font-size: font-size(13px);
  padding-top: 0px;
  padding-right: $spacer;
  padding-bottom: 0px;
  padding-left: ($spacer * 2);
}
// thead{
//     background: #F5F6FA;
// }
table > thead input {
  font-size: font-size(13px);
  height: ($spacer * 3);
  background-color: theme-color("white") !important;
  border: unset;
  width: -webkit-fill-available;
  padding: $spacer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 0px;
}
table > thead select {
  appearance: none;
  @include border("right", 15px, transparent);
  content: "";
  background-image: url('data:image/svg+xml,<svg class="bi bi-chevron-down" width="30" height="30" viewBox="15 -16 38 28" fill="8C8C8C" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>');
  background-repeat: no-repeat;
  background-size: 200% 18px;
  height: ($spacer * 4);
  width: 100%;
  font-size: font-size(13px);
  background-color: theme-color("white");
  color: #8c8c8c;
  font-weight: $font-weight;
  border: unset;
  text-align: left;
  border-radius: 0px;
  box-shadow: unset;
  padding: $spacer;
}

// .table th, .table td,.table thead th{
//     border: unset;
// }

// table>thead>tr{
//     border-radius: $spacer;
//     border: unset;
// }

/* Select dropdown-css */

/* Default dropdown */
.dropdown .btn::after {
  color: transparent;
  float: right;
  vertical-align: -4px;
  @include border("top", 0.3em, transparent);
  @include border("right", 15px, transparent);
  @include border("left", 2px, transparent);
  display: list-item;
  content: "";
  background-image: url('data:image/svg+xml,<svg class="bi bi-chevron-down" width="1em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>');
  background-repeat: no-repeat;
  background-size: 14px 12px;
}

.dropdown {
  .btn {
    width: ($spacer * 49);
    background-color: theme-color("coolgray10");
    color: black;
    font-weight: $font-weight;
    padding: $spacer;
    border: unset;
    text-align: left;
    border-radius: 0px;
    box-shadow: unset;
  }
}

select {
  height: ($spacer * 4);
  width: ($spacer * 49);
  font-size: font-size(13px);
  background-color: theme-color("coolgray10");
  color: black;
  font-weight: $font-weight;
  border: unset;
  text-align: left;
  border-radius: 0px;
  box-shadow: unset;
  padding: 0px 24px 0px 10px !important;
  appearance: none;
  @include border("left", 2px, transparent);
  content: "";
  background-image: url('data:image/svg+xml,<svg class="bi bi-chevron-down" width="30" height="30" viewBox="15 -14 20 28" fill="8C8C8C" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>');
  background-repeat: no-repeat;
  background-size: 200% 22px;
}

.outline-select {
  background-color: theme-color("white") !important;
  color: black !important;
  border: 1px solid theme-color("notsocoolgray") !important;
}

.dropdown {
  .dropdown-menu {
    width: 100%;
    background-color: theme-color("white") !important;
    padding: 0;
  }
}
.dropdown .dropdown-menu .dropdown-item {
  line-height: ($spacer * 3);
}

.outline-dropdown > button {
  background-color: theme-color("white") !important;
  color: black !important;
  border: 1px solid theme-color("notsocoolgray") !important;
}
/* Checkbox css */
// input[type="checkbox"]{
//     height: ($spacer * 2);
//     width: ($spacer * 2);
// }
// .checkbox::after{
//     background-color: theme-color("black");
// }
/* Radio button */
input[type="radio"] {
  height: ($spacer * 2);
  width: ($spacer * 2);
  top: -1px;
  left: 14px;
  margin-left: ($spacer * -2);
}

input[type="radio"]:checked::after {
  top: -12px;
}
.radio-button-container {
  display: block;
  position: relative;
  // padding-left: ($spacer * 3);
  cursor: pointer;
  font-size: font-size(16px);
  user-select: none;
  & .PrivateSwitchBase-root-98 {
    padding-top: 0 !important;
  }
  & .MuiIconButton-root:hover {
    background-color: unset !important;
  }
  & .radio-button-icon {
    box-shadow: unset !important;
    background-image: unset !important;
    background-color: #f0f2f7 !important;
  }
  & .radio-button-checked:before {
    width: 18px !important;
    height: 18px !important;
    content: "";
    display: block !important;
    background-image: radial-gradient(
      #0062ff,
      #0062ff 28%,
      transparent 32%
    ) !important;
    position: relative !important;
    top: -1px !important;
    left: -1px !important;
  }
  // & .radio-button-checked{
  //     background-color: unset !important;
  //     background-image: unset !important;
  // }
  & .MuiTypography-body1 {
    background-color: unset !important;
  }
  & .MuiFormHelperText-root {
    color: #8c8c8c !important;
    margin-top: 0 !important;
    margin-left: 24px !important;
    padding-bottom: 16px !important;
  }
}
.radio-button-container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-button-container .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: ($spacer * 2);
  width: ($spacer * 2);
  background-color: theme-color("coolgray10");
  border-radius: $spacer;
}
.radio-button-container input[type="radio"]:checked ~ .checkmark {
  background-color: theme-color("coolgray10");
}

.radio-button-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-button-container input[type="radio"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: $spacer;
  height: $spacer;
  border-radius: $spacer;
  background: theme-color("blue60");
}
/* Input search box with icon */

.search-input-box {
  input {
    padding-right: ($spacer * 4);
    color: #a4a4a4;
    border: 1px solid theme-color("notsocoolgray");
    border-radius: 0px;
  }
}
.search-input-box {
  .input-icons i {
    margin-top: -3px;
    margin-left: ($spacer * 16);
    position: absolute;
  }
}
.search-input-box {
  .icon {
    padding: $spacer;
    min-width: 50px;
    text-align: center;
  }
}

/* Popup box css */

.modal-content {
  border-radius: 2px;
  height: ($spacer * 81);
}
.modal-header {
  border-bottom: unset;
}
.modal-title {
  position: absolute;
  right: 15px;
  top: 10px;
  // margin-top: -10px;
}

.modal-content {
  .grid-1 {
    justify-content: space-evenly;
  }
}
.modal-content {
  h1 {
    margin-top: $spacer;
    font-size: font-size(40px);
    font-weight: $font-weight;
  }
}
.modal-content {
  h3 {
    margin-top: $spacer;
    font-size: font-size(16px);
    font-weight: $font-weight;
  }
}
.modal-title {
  .btn-close {
    padding: $spacer !important;
    color: theme-color("black") !important;
    height: ($spacer * 4) !important;
    box-shadow: unset !important;
    background-color: transparent !important;
  }
}

.modal-title {
  .btn-close svg {
    height: ($spacer * 3);
    width: ($spacer * 3);
    margin-top: 0px;
  }
}

.modal-content {
  .modal-header {
    .grid-1 {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* Context menu */

.contextMenu {
  background-color: theme-color("white");
  width: ($spacer * 18);
  list-style: none;
  position: absolute;
  z-index: 1000;
  padding: $spacer;
  // margin-top: ($spacer * -13);
  box-shadow: 0px 2px 6px theme-color("coolgray10");
  border-radius: 2px;
}
.contextMenu > li {
  width: 100%;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
}

.contextMenu > li > label {
  padding: $spacer;
  font-size: font-size(13px);
  cursor: pointer;
}

.tab-container {
  padding-top: ($spacer * 8);
}

.cancel-btn {
  background-color: theme-color("white") !important;
  color: theme-color("blue60") !important;
  border-radius: unset !important;
  &:focus {
    outline: unset;
  }
  span {
    font-size: 12px;
    text-transform: capitalize;
  }
}
.create-btn {
  background-color: theme-color("blue60") !important;
  color: theme-color("white") !important;
  border-radius: unset !important;
  &:focus {
    outline: unset;
  }
  span {
    font-size: 12px;
    text-transform: capitalize;
  }
}

thead {
  tr {
    background: #f5f6fa;
    th {
      box-shadow: -1px 0px 1px 1px #f5f6fa, 2px 0 2px -5px #f5f6fa;
    }
    .search-name {
      background: theme-color("white");
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      padding-bottom: 0px;
      width: 100%;
      &::placeholder {
        font-family: $font-stack;
      }
      &:hover {
        background: theme-color("white");
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      }
    }
    .search-owner {
      background: theme-color("white");
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      padding-bottom: 0px;
      width: 100%;
      &::placeholder {
        font-family: $font-stack;
      }
      &:hover {
        background: theme-color("white");
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      }
    }
    .search-checkbox {
      background: theme-color("white");
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
    }
    .search-time {
      background: theme-color("white");
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      &::placeholder {
        font-family: $font-stack;
      }
    }
    .search-filesize {
      background: theme-color("white");
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      padding-bottom: 0px;
      width: 100%;
      &::placeholder {
        font-family: $font-stack;
      }
      &:hover {
        background: theme-color("white");
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
      }
    }
  }
}
tbody {
  background: theme-color("white");
  tr {
    //box-shadow: 0px -1px 0px #F0F2F7;
    td {
      border-bottom: 1px solid #f0f2f7;
    }
    .drive-folder-image {
      width: 28px;
      height: ($spacer * 3);
    }
  }
}

/* pagination */

.pagination {
  justify-content: flex-end;
  background: theme-color("white");
  display: flex;
  box-shadow: 0px -1px 0px 1px #eef0f5;
  input {
    background: theme-color("white");
    width: 60px !important;
    height: 16px !important;
    background: #edf4ff;
    margin-top: $spacer;
    margin-bottom: $spacer;
    margin-right: $spacer;
  }
  span {
    background: theme-color("white");
  }
  button {
    background: theme-color("white");
    border: none;
  }
  select {
    background: theme-color("white");
    width: 100px !important;
    margin-top: $spacer;
    margin-right: $spacer;
    background: #edf4ff;
  }
  .pageNumber {
    padding-top: 13px !important;
    padding-left: $spacer !important;
    padding-right: $spacer !important;
  }
}

.MuiDialog-paperScrollPaper {
  height: 75vh;
}

/* Dialog tile and close icon */

#scroll-dialog-title {
  .btn-close {
    position: absolute;
    right: 0px;
    background: white;
    box-shadow: none;
    color: black;
    top: 10px;
    svg {
      height: ($spacer * 3);
      width: ($spacer * 3);
    }
  }
  #modal-title {
    position: relative;
    // top: 32px;
    text-align: center;
    margin-top: 0;
  }
}

.MuiDialogTitle-root {
  padding-top: ($spacer * 7) !important;
  text-align: center;
  h2 {
    font-size: font-size(40px);
  }
}

.MuiDialogActions-root {
  padding-bottom: ($spacer * 2) !important;
  padding-top: ($spacer * 2) !important;
  border-top: 1px solid theme-color("coolgray10");
  button {
    margin-right: $spacer;
  }
}

.MuiButton-root.Mui-disabled {
  color: theme-color("white") !important;
}

.MuiContainer-root {
  outline: unset !important;
}

.MuiDialogContentText-root {
  outline: unset !important;
}

.MuiButton-root {
  &:focus {
    outline: unset !important;
  }
}

.MuiInput-underline:before {
  border-bottom: unset !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.borderRed {
  border: 1px solid red !important;
  border-radius: 2px !important;
}

.MuiDialog-paperWidthSm > .MuiDialogContent-root > div {
  margin-top: 32px;
}

.MuiButton-containedRed {
  color: white !important;
  background-color: #da1e28 !important;
}

.userTask-Title {
  display: flex;
  .user-icon {
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .arrow-back-icon {
    cursor: pointer;
    height: 40px;
  }
  .MuiBreadcrumbs-ol,
  button {
    flex-wrap: inherit !important;
    font-size: 18px;
    a {
      background: transparent;
    }
  }
}

.mxDisabled {
	opacity: 0.2 !important;
	cursor:default !important;
}