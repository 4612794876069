.afMainWrapper {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.afWrapper {
  display: flex;
  width: 856px;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  flex-direction: column;
  background-color: white;
  padding: calc(var(--spacer) * 1.5px);
  /* max-height: calc(100% - (var(--spacer) * 8px));
  min-height: calc(100vh - (var(--spacer) * 30px));   */
  height: calc(100% - (var(--spacer) * 12px));  /* reduced height to 8 to 30px*/
}

.afPopupBody {
  overflow: scroll;
}

.afCloser {
  margin: 0px;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  text-align: center;
  top: calc(var(--spacer) * 1px);
  right: calc(var(--spacer) * 1px);
  width: calc(var(--spacer) * 3px);
  height: calc(var(--spacer) * 4px);
  font-size: calc(var(--spacer) * 2.5px);
  z-index: 98;
}

.afHeader {
  color: #000000;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin: 10px;
  word-wrap: break-word;
}

.afSubHeader {
  text-align: center;
  font-size: 14px;
}