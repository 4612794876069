.customcb{
  display:flex !important;
  align-items: center !important;
  justify-content: center;
}
.customcb input[type="checkbox"]{
  display:none;
}
.customcb svg{
  width: 24px;
  height: 24px;
  /* fill: rgb(205, 212, 228); */
  fill: #777;
}
