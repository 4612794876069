.code-generator {
    margin-left: 320px;
    position: relative;
}
.code-generator-full {
    margin-left: 0;
    position: relative;
}
.integration-builder-code-mirror {
    height: 100%;
}
.integration-builder-code-mirror > .CodeMirror {
    height: 100%;
    background-color: transparent;
}
.code-generator {
    background: transparent;

    margin-top: 20px;
}
.code-generator .CodeMirror-linenumber{
    text-align: center;
}
.code-title {
    font-weight: 600;
}
.CodeMirror-gutters{
    background-color: transparent;
}
.CodeMirror-scroll{
    height: calc(100vh - 172px);
}
#customDialog {
    background-color: rgb(255, 255, 255);
    width: 400px;
    margin: auto;
    padding: 10px;
    display: block;
    /* width: 440px !important;
    height: 325px;
    max-width: 600px; */
    width: 440px !important;
    height: 272px;
    max-width: 600px;
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.customDialogMessage {
    width: 112px;
    height: 32px;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    margin: 0;
}
.deleteMessage{
    display: inline;
}
.deleteContent{
    height: 150px;
}
.customDialogTitle {
    /* text-align: center; */
    font-size: 24px;
    /* background: url('../../assets/images/jsbuilder/close.png');
    background-repeat: no-repeat;
    background-size: 13px 13px;
    background-position: right 10px top 25px; */
    /* padding-top: 20px; */
    padding-left: 24px;
    padding-top: 16px;
    font-weight: 500;
}
#customDialogClose{
    position: absolute;
    top: 33px;
    right: 18px;
    cursor: pointer;
    height: 12px;
    width: 12px;
}
#customDialogInput {
    padding: 0px 0px 0px 8px;
    font-size: 13px;
    width: 100%;
    height: 32px;
    position: relative;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-radius: 0px !important;
    background-color: #f0f2f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.customDialogButtons {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #f0f2f7;
}
#customDialogCancel {
    color: #0062ff;
    background-color: #ffffff;
    border: unset;
    cursor: pointer;
    height: 40px;
    padding: 6px 16px;
    font-size: 14px;
    min-width: 64px;
    box-shadow: none;
    box-sizing: border-box;
    font-family: Saira, Roboto, Verdana;
    font-weight: normal;
    line-height: 1.75;
    padding-left: 16px;
    border-radius: 2px !important;
    padding-right: 16px;
    text-transform: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}
#customDialogOkay {
    color: #ffffff;
    background-color: #0062ff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border: unset;
    cursor: pointer;
    height: 40px;
    padding: 6px 16px;
    font-size: 14px;
    min-width: 64px;
    box-shadow: none;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Saira, Roboto, Verdana;
    font-weight: normal;
    line-height: 1.75;
    padding-left: 16px;
    border-radius: 2px !important;
    padding-right: 16px;
    text-transform: none;
}
.customBodyContent {
    flex: 1 1 auto;
    padding: 45px 24px;
    padding-top: 30px;
    padding-bottom: 10px;
}
#customDialogInput:focus {
    outline: none;
}
