.create-plugin-container {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.create-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  width: 285px;
}

.plugin-image {
  height: 200px;
  width: 200px;
}

.align-plugin-form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: auto;
  margin-bottom: calc(var(--spacer) * 9px);
}

.inner-plugin-form {
  width: 450px;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.inner-plugin-form::-webkit-scrollbar {
  display: none;
}
