.total-container{
 display: flex;
 flex-direction: column;
 margin-top: 2%;
}
.tenant-header{
 /* background-color: blue; */
 /* display: flex;
 justify-content: space-around; */
 display: flex;
 padding: 0 !important;
 margin-left: 12%;
 
}
.admin-tools{
    margin-top: -2%; 
    margin-left: -6%;
    color: #676767;
    font-size: 15px;
    font-family: Saira;
    font-weight: normal;
    line-height: 24px;
}
.header-text{
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
}
.btn{
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}
.tenant-data-container{
    box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
    width: 85%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    height: 550px; 
    margin-left: 8%;
}
.tenent-btn{
    color: #ffffff;
    background-color: #0062ff;
    border: none;
    font-family: Saira, Roboto, Verdana;
    font-weight: normal;
    line-height: 1.75;
    height: 40px;
    font-size: 14px;
    padding: 0px 0 0 0%;
    width: 50%;
}
.tenant-text{
    font-size: 32px;
    font-family: Saira;
    font-weight: normal;
    line-height: 40px;
    margin-left: -7%;
}