$break-mobilesmin: 320px;
$break-mobilesmax: 375px;
$break-mobileslmin: 375px;
$break-mobilemmin: 425px;
$break-mobilemmax: 575px;
$break-mobilelmin: 576px;
$break-mobilelmax: 767px;
$break-tabletmin: 768px;
$break-tabletmax: 992px;
$break-laptopmin: 1200px;


@mixin  mobile-s {
    @media only screen and (min-width: $break-mobilesmin) and (max-width: $break-mobilesmax) { @content; }
}
@mixin  mobile-sl {
    @media only screen and (min-width: $break-mobileslmin) and (max-width: $break-mobilemmin) { @content; }
}
@mixin  mobile-l {
    @media only screen and (min-width: $break-mobilemmin) and (max-width: $break-mobilemmax) { @content; }
}
@mixin  mobile-m {
    @media only screen and (min-width: $break-mobilelmin) and (max-width: $break-mobilelmax) { @content; }
}
@mixin  tablet {
    @media only screen and (min-width: $break-tabletmin) { @content; }
}
@mixin  tablet-all {
    @media only screen and (min-width: $break-tabletmin) and (max-width:$break-tabletmax) { @content; }
}
@mixin  laptop-s {
    @media only screen and (min-width: $break-tabletmax) and (max-width:$break-laptopmin) { @content; }
}
@mixin  laptop-all {
    @media only screen and (min-width: $break-laptopmin) { @content; }
}

@mixin laptop-no {
    @media only screen and (max-width: $break-tabletmax) { @content; }
}
