.afFooter {
    bottom: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--spacer) * 9px);
    padding: 0px calc(var(--spacer) * 1.5px);
    width: calc(100% - (var(--spacer) * 6px));
    border-top: 1px solid var(--pageBackground);
}

.popupTitle {
    margin-top: calc(var(--spacer) * 2px);
}

.formArea {
    display: flex;
    justify-content: center;
    /* margin: calc(var(--spacer) * 5px) 0px; */
}

.column {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.triggerHeader {
    font-size: 3rem;
    text-align: center;
}

.triggerComp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 400px;
    margin-bottom: 1rem;
}

.triggerCompInput {
    width: 104px;
    height: 100%;
    outline: none;
    padding: 0 4px;
    background-color: #F0F2F7;
    border: none;
    margin: 0 10px;
}

.triggerCompInput::-webkit-outer-spin-button,
.triggerCompInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.triggerCompSelect {
    background-color: transparent;
    outline: none;
}

.triggerButton {
    background: none;
    border: none;
    margin: 1rem;
    text-align: inherit;
    padding-left: 8px;
    color: var(--primaryColor);
    cursor: pointer;
    width: 9rem;
}

.trigger {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable {
    color: var(--inputBorderColor);
    cursor: not-allowed;
    background: none;
    border: none;
    margin: 1rem;
    text-align: inherit;
    padding-left: 8px;
    width: 9rem;
}

.addTrigger {
    width: 8rem;
    color: var(--primaryColor);
    cursor: pointer;
}

.addTrigger:hover {
    text-decoration: underline;
}

.deleteTrigger {
    color: gray;
    cursor: pointer;
}

.triggerCompDays {
    display: flex;
    align-items: center;
    margin: 1rem;
    width: 100px;
    margin-left: 120px;
}

.plusIcon {
    margin-left: 1rem;
    color: var(--primaryColor);
    cursor: pointer;
}

.triggerCompMonths {
    display: flex;
    align-items: center;
    margin: 1rem;
    margin-left: 120px;
}

.error {
    text-align: center;
    color: red;
}