@import "../../pillirthemevariables.scss";
@import "../../media-query.scss";

.new-project-desc-container .grid-2 .row{
    display: block;
    padding-bottom: ($spacer * 3);
}
.new-project-desc-container>.grid-2>.container{
    margin-left: ($spacer * 22);
    margin-top: ($spacer * 6);
    @include mobile-s{
        margin-left: auto;
    }
    @include mobile-sl{
        margin-left: auto;
    }
    @include mobile-l{
        margin-left: auto;
    }
    @include mobile-m{
        margin-left: auto;
    }
    @include tablet-all{
        margin-left: auto;
    }
}

.new-project-desc-container>.grid-2 .container .row{
    display: grid;
}



.new-project-selection-container>.grid-2{
    margin:0;
    margin-top: ($spacer * 4);
    text-align-last: center;
}

.new-project-selection-container .grid-2 .input, .form-group input{
    @include mobile-s{
        width: 100% !important;
    }
    @include mobile-sl{
        width: 100% !important;
    }
    @include mobile-l{
        width: 100% !important;
    }
    @include mobile-m{
        width: 100% !important;
    }
    @include tablet-all{
        width: 100% !important;
    }
}

.new-project-selection-container .grid-2 .textarea, .form-group textarea{
    @include mobile-s{
        width: 100% !important;
    }
    @include mobile-sl{
        width: 100% !important;
    }
    @include mobile-l{
        width: 100% !important;
    }
    @include mobile-m{
        width: 100% !important;
    }
    @include tablet-all{
        width: 100% !important;
    }
}

.new-project-selection-container>.grid-2 .img-row{
    padding-bottom: ($spacer * 3);
    height: ($spacer * 28);
    display: inherit;
}

.new-project-selection-container>.grid-2 .text-row{
    justify-content: space-evenly;
    height: 34%;
}

.new-project-selection-container>.grid-2 .text-row h5{
    margin-bottom: 0px;
    
}
.new-project-selection-container>.grid-2 .text-row p{
    width: 92%;
    text-align: center;
    line-height: ($spacer * 3);
    

}

.new-project-selection-container>.grid-2 .btn-row{
    justify-content: center;
}
.btn-row button{
    font-size:font-size(13px);
    width: ($spacer * 20);
}

.new-project-createapp-container .grid-1{
    justify-content: space-evenly;
}
.new-project-createapp-container .grid-2{
    margin-top: ($spacer * 9);
    justify-content: space-around;
}


.device-select-btn .btn{
    padding-top:$spacer;
   background:white;
   color:#000;
   border: 1px solid #CDD4E4;
   border-radius:0px;
   $font-weight: 500;
    font-weight: $font-weight;
    font-size: font-size(14px);
    box-shadow: unset;
}
.device-select-btn .btn-active,.btn-primary:not(:disabled):not(.disabled).active{
    border:0px !important;
    border-color:theme-color("blue60") !important;
    background:theme-color("blue60") !important;
    color:theme-color("white");
 }

 
.template-card-container{
    display:contents;
}
 .new-project-template-container .grid-3{
    position: absolute;
    overflow: scroll;
    max-height: ($spacer * 46);
    margin-left: ($spacer * -4);
    @include mobile-s{
        position: relative;
    }
    @include mobile-sl{
        position: relative;
    }
    @include mobile-l{
        position: relative;
    }
    @include mobile-m{
        position: relative;
    }
    @include tablet-all{
        position: relative;
    }
    @include laptop-s{
        position: relative;
    }
 }

 .new-project-template-container .grid-2{
    margin-left: -25px;
    margin-right: -35px;
 }

 .new-project-template-container .column-1{   
     padding-right: $spacer;
    padding-left: $spacer;
}
 .new-project-template-container .column-2{
    padding-left: $spacer;
    padding-right: $spacer;
 }
 .new-project-template-container .column-3{
    padding-left: $spacer;
    padding-right: $spacer;
 }


 .new-project-manager-container .grid-1 .col{
    flex-grow: 0;
 }
 .new-project-manager-container .grid-2 label{
    $font-weight: 500;
    font-weight: $font-weight;
}
 .new-project-manager-container .grid-2 p{
     margin-bottom: 0px;
 }

 .new-project-manager-container .grid-2 {
    margin-left: ($spacer * 9);
    @include mobile-s{
        margin-left: 0;
    }
    @include mobile-sl{
        margin-left: 0;
    }
    @include mobile-l{
        margin-left: 0;
    }
 }

 .new-project-manager-container .grid-2 .ml-5{
    margin-left: ($spacer * 9);
    @include mobile-s{
        margin-left: 0 !important;
    }
    @include mobile-sl{
        margin-left: 0 !important;
    }
    @include mobile-l{
        margin-left: 0 !important;
    }
    @include mobile-m{
        margin-left: 0 !important;
    }
    @include tablet-all{
        margin-left: 0 !important;
    }
 }

 .new-project-manager-container .grid-3 .card{
    min-width: ($spacer * 36);
    width: ($spacer * 36);
    border: unset;
    height: ($spacer * 36);
    border-radius: 12.5217px;
    background: theme-color("notsocoolgray");
    margin-right: ($spacer * 4);
 }

 .new-project-manager-container .grid-3 .device-css{
    height: max-content;
    padding-top: ($spacer * 3);
    margin-right: auto;
    margin-left: auto;
    
 }
 .new-project-manager-container p,.new-project-manager-container label{
    font-size: font-size(15px);
 }
 .new-project-manager-container{
    max-height: ($spacer * 65);
    position: absolute;
    overflow-y: scroll;
    padding-bottom: ($spacer * 3);
    @include mobile-s{
        position: relative;
    }
    @include mobile-sl{
        position: relative;
    }
    @include mobile-l{
        position: relative;
    }
}
 .new-project-manager-container .grid-3 .container{
    margin-top: ($spacer * 3);
    display: inline-flex;
    max-width: ($spacer * 96);
    overflow-x: scroll;
 }
 .modal-header{
     justify-content: space-evenly;
 }

 .modal-header .grid-1 .project-manager h2,
 .modal-header .grid-1 .project-manager p,
 .modal-header .grid-1 .project-manager label{
    width: 708px;
    position: relative;
    left: -128px;
    @include mobile-s{
        left: unset;
        width: unset;
    }
    @include mobile-sl{
        left: unset;
        width: unset;
    }
    @include mobile-l{
        left: unset;
        width: unset;
    }
    @include mobile-m{
        left: unset;
        width: unset;
    }
    @include tablet-all{
        left: unset;
        width: unset;
    }
    @include laptop-s{
        left: unset;
        width: unset;
    }
 }

 .modal-header .grid-1 .project-manager h2{
    height: ($spacer * 6);
    line-height: ($spacer * 6);
    @include mobile-s{
        height: ($spacer * 6);
        line-height: ($spacer * 3);
        font-size: 20px;
        text-align: inherit;
    }
    @include mobile-sl{
        height: ($spacer * 6);
        line-height: ($spacer * 3);
        font-size: 20px;
        text-align: inherit;
    }
    @include mobile-l{
        height: ($spacer * 6);
        line-height: ($spacer * 3);
        font-size: 20px;
        text-align: inherit;
    }
 }
 .modal-header .grid-1 .project-manager p{
    height: ($spacer * 3);
    line-height: ($spacer * 3);
    @include mobile-s{
        height: $spacer;
        line-height: 17px;
        font-size: 12px;
    }
    @include mobile-sl{
        height: $spacer;
        line-height: 17px;
        font-size: 12px;
    }
    @include mobile-l{
        height: $spacer;
        line-height: 17px;
        font-size: 12px;
    }
 }

 .modal-header .grid-1 .project-manager label{
    @include mobile-s{
        margin-top: ($spacer * 6);
    }
    @include mobile-sl{
        margin-top: ($spacer * 6);
    }
    @include mobile-l{
        margin-top: ($spacer * 6);
    }
    @include tablet-all{
        margin-top: ($spacer * 4);
    }
}
.modal-header .grid-1{
   margin-top: ($spacer * 4);
}
.modal-header .grid-1 h2{
   font-size: 25px;
   text-align: center;
}
.modal-content{
    @include mobile-s{
        height: 100% !important;
    }
    @include mobile-sl{
        height: 100% !important;
    }
    @include mobile-l{
        height: 100% !important;
    }
    @include mobile-m{
        height: 100% !important;
    }
    @include mobile-l{
        height: 100% !important;
    }
    @include tablet-all{
        height: 100% !important;
    }
    @include laptop-s{
        height: 100% !important;
    }
}

.new-project-selection-container .grid-2 .col-12 {
   @include mobile-s{
       margin-top: ($spacer * 7) !important;
   }
   @include mobile-sl{
       margin-top: ($spacer * 7) !important;
   }
}
.new-project-selection-container .grid-2 .col-sm-12 {
   @include mobile-l{
       margin-top: ($spacer * 7) !important;
   }
}
.new-project-selection-container .grid-2 .col-md-6 {
   @include mobile-l{
       margin-bottom: ($spacer * 7) !important;
   }
}

.new-project-template-container .search-input-box .input-icons i{
    @include mobile-s{
        margin-left: ($spacer * 12);
    }
    @include mobile-sl{
        margin-left: ($spacer * 12);
    }
    @include mobile-l{
        margin-left: ($spacer * 12);
    }
}
.new-project-template-container .template-card-container .device-template {
    @include mobile-s{
        width: 100% !important;
    }
    @include mobile-sl{
        width: 100% !important;
    }
    @include mobile-l{
        width: 100% !important;
    }
}

.task-title {
    font-size: 25px;
    text-align: left;
    width: 808px;
    height: 40px;
    left: 40px;
    margin-top: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
 }

 .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index:9999;
  }
   
  .box {
    // position: relative;
    width: 70%;
    margin: 0 auto;
    // height: auto;
    max-height: 95vh;
    // margin-bottom: 10px;
    margin-top: 10px;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }

  .task_left_layout {
                                                                                                        
    max-height: 100vh;
    height: auto;
    overflow-y:scroll;
    position: relative;
    overflow-x: hidden;
  }

  .task_card_subtitle_bg_layout {
    display: flex;
    flex-direction: row;
    padding: 0px ($spacer);
    width: ($spacer*12.25);
    height: ($spacer*3);
    left: ($spacer*5);
    top: ($spacer*10);
    background: theme-color("coolgray10");
    border-radius: 4px;
}

.task_card_subtitle_layout {
    position: static;
    width: ($spacer*10.25);
    height: ($spacer*3);
    left: ($spacer);
    top: 0px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: font-size(13px);
    line-height: ($spacer*3);
    color: theme-color("black");
    flex: none;
    order: 0;
    align-self: center;
    margin: ($spacer*1.25) 0px;
}

.task_card_image_layout {

    margin-left: $spacer*2;
    top: 0px;
    width: $spacer*3;
    height: $spacer*3;
    background-blend-mode: normal;
    border-radius: 2.4px;

    background-image: url(https://www.pngkit.com/png/detail/231-2318671_businesswoman-blank-profile-picture-female.png);
    background-size: cover;
}

.task_purchase_plus {

    margin-left: $spacer;
    top: 0px;
    width: $spacer*2;
    height: $spacer*2;
    background-blend-mode: normal
}

.task_card_description_layout {
    
    left: ($spacer*5);
    right: $spacer*14;
    top: $spacer*2;
    height: $spacer*5;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: $spacer*5;
    color: #000000;
}

.task_card_descTextArea_layout {

    display: inline-table;
    right: $spacer*5;
    height: $spacer*9;
    left:($spacer*5);
    top: 0px;
    background: #F0F2F7;
    margin: auto;
    max-width: 100%;
}

.table {
    margin: 1em;
    display: flex;
    // flex-direction: column;
    
    .header, .row {
      display: flex;
      
      row_div {
        width: $spacer*37.5;
        left:($spacer*5);
        flex: 1;

      }
    }
    
    .header {
      background-color: rgb(238, 238, 238);
      div { 
        cursor: pointer;
      }
    }
  }

  .checkbox{
    display:inline-block;
    width: $spacer*2;
    height: $spacer*2;
    left: $spacer*5;
    top: $spacer*2;
    background-color: #F0F2F7;
    
    // background-image:  url("../../assets/images/task-checktick.png");
    // background-position: center center;
  
}


.checkboxImage{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top: 5px;
 
}

.checkboxTitle{

    margin-left: $spacer;
    right: 0px;
    margin-top: -$spacer;
    // bottom: -8px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: $spacer*4;
    display: flex;
    align-items: center;
    color: #000000;
}

.activityImage{

    left: $spacer*5;
    margin-top: $spacer*2;
    width: $spacer*4;
    height: $spacer*4;
    border-radius: 3.2px;

    // background-image: url(https://www.pngkit.com/png/detail/231-2318671_businesswoman-blank-profile-picture-female.png);
    background-size: cover;
    background-blend-mode: normal;
    border-radius: 3.2px;
}

.activityComment {

    display:inline-table;
    right: $spacer*5;
    height: $spacer*4;
    left: $spacer;
    margin-top: $spacer*2;
    background: #F0F2F7;
    max-width: calc(100% - 88px);
}

.activityCommentTitle {

    position: static;
    left: $spacer;
    margin-top: 0px;
    margin-bottom: 0px;

    font-style: normal;
    font-weight: $spacer*62.5;
    font-size: 13px;
    line-height: $spacer*2;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 0;
    align-self: center;
    margin: $spacer 0px;
}
.activityCommentTime {
    padding-left: $spacer;
    right: 0px;
    top: 0px;
    bottom: 0px;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: $spacer*2;
    display: flex;
    align-items: center;
    color: #A4A4A4;
    flex: none;
    order: 1;
    align-self: center;
    margin: $spacer 0px;
}

.activityCommentReply {

    margin-right:$spacer;
    // display: flex;
    // flex-direction: row;
    margin-left:180px;
    height: 16px;
    width: 33px;
  

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: $spacer*2;
    color: #0062FF;
    margin-top: $spacer;
    order: 2;
}

.activityCommentLayout {


    left: $spacer;
    right: $spacer;
    top: $spacer*2;
    max-width: 300px;
    height:auto;
    word-wrap: break-word;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    // display: absolute;
    // left: $spacer;
    // top: $spacer*2;
    // width: calc(100% - 88px);
    // height:30px;
    // // bottom: $spacer*18;
    // background-color: red;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 13px;
    // line-height: $spacer*2;
    // // align-items: center;
    // color: #000000;
    // flex-wrap: wrap;
}

.taskScroll {

    // display: absolute;
    width: 114%;
    // left: 440x;
    left:0px;
    right: 0px;
    margin-top: -($spacer*2);
    height: 646px;
    overflow: scroll;
    background-color:lightgray;
}

.taskScrollImage {

    width: 820px;
    height: 792.74px;
    left: 0px;
    top: 0px;
    background-size: cover;
    overflow: scroll;
    display: inline-block;
    
}

.to-do-buttonbg-layout{

    width: 276px; 
    height: 40px;
    left: ($spacer * 5);;
    padding-top: 0px;
    margin-right: "auto";
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

}

.to-do-button-layout{

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 40px;
    color: #0062FF;
    border : 0px;
    background-color: white;
    outline: none !important;
    outline-offset: none !important;
    
}

.todo_addform_layout {
    left: ($spacer * 5);
    width: ($spacer * 36);
    height: ($spacer * 5);
    padding-top: ($spacer);
    margin-bottom: ($spacer);
    background-color: theme-color("white");
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.todoform_texfield_layout
{
    display: inline-block;
    top: ($spacer);
    right: ($spacer*16);
    left:($spacer);
    height: ($spacer *3);
    width: ($spacer * 35);
    background-color: theme-color("white");
    outline-width: 0;
    font-style: normal;
    font-size: font-size(16px);
    // align-content: flex-start;

}

.TaskCard_subTitle_layout {

    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: ($spacer*12.25);
    height: ($spacer*3);
    left: ($spacer*5);
    top: ($spacer*2);
    padding-top: 4;
    background: theme-color("coolgray10");
    font-style: normal;
    font-size: font-size(13px);
    color: theme-color("black");
    align-content : center;
    font-weight: 20;
    // margin: ($spacer*1.25) 0px;
}

.left_view_size {
    width : 440px;
    overflow-y: scroll;
    display: inline-block;
}









   