.buttonWidth {
    width: 125px !important;
  }

  .caOuterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    background-color: #f0f2f7;
    padding-top: 20px;
  }
  
  .caContainer {
    box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
    width: 84%;
    border-radius: 8px;
    display: flex;
    height: 550px;
    overflow: hidden;
  }
  
  .caInnerContainer {
    width: 100%;
    background-color: #f0f2f7;
    position: relative;
  }
  
  .caTableContainer {
    overflow-y: scroll;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: white;
    height: 501px;
  }