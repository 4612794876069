.headerContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.buttonWidth {
  width: 125px !important;
}

.varOuterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  background-color: #f0f2f7;
  padding-top: 20px;
}

.varContainer {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  width: 84%;
  border-radius: 8px;
  display: flex;
  height: 550px;
  overflow: hidden;
}

.varInnerContainer {
  width: 100%;
  background-color: #f0f2f7;
  position: relative;
}

.varTableContainer {
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  height: 501px;
}

.popupContainer {
  margin: 10px 0 0 10px;
  padding: 2px;
  box-sizing: border-box;
  height: 480px;
  width: 90%;
  overflow-y: scroll;
}

.formContainer {
  margin: 8px 2px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 13px;
}

.title {
  font-size: 25px !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.submitContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  padding: 16px 10px 8px;
  border-top: 2px solid #f0f2f7;
}

.columnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.columnInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.addField {
  width: 120px !important;
}

.formContainer select {
  cursor: pointer;
}

.variableName {
  padding: 0 0 0 8px !important;
  opacity: 0.75 !important;
  height: 32px !important;
  font-size: 13px !important;
  margin-top: 5px !important;
  border: none !important;
  background-color: #f0f2f7 !important;
  box-sizing: content-box !important;
}

.variableName:disabled {
  background-color: #3b3b3b33 !important;
}

.variableType,
.variableValue {
  padding: 0 0 0 8px !important;
  height: 32px !important;
  font-size: 13px !important;
  margin-top: 5px !important;
  border: none !important;
  background-color: #f0f2f7 !important;
  box-sizing: content-box !important;
}

.variableValue:hover {
  background-color: #3b3b3b33 !important;
  cursor: text;
}

.tableValue {
  padding: 8px !important;
  font-size: 13px !important;
  margin-top: 5px !important;
  border: none !important;
  background-color: #f0f2f7 !important;
  box-sizing: content-box !important;
}

.tableValue:hover {
  background-color: #3b3b3b33 !important;
  cursor: text;
}

.radioCont {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.radioCont input {
  cursor: pointer;
}