.tableHeader{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--pageBackground);
    padding-top: calc(var(--spacer) * 5px);
    padding-bottom: calc(var(--spacer) * 1px);
    margin-bottom: 28px;
    margin-top: 28px;
}

.tableWrapper{
    box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
    width: 100%;
    background-color: white;
    margin: 30px auto 15px;
    border-radius: 8px;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
}

.mainWrapper{
    /* width: 95%; */
    width: 100%; /* check */
}

.subWrapper{
    flex-basis: 100%;
    flex-grow: 0;
    width: 100%;
}
  
.spinner {
    --size: 1.5rem;
    --color: #CDD4E4;
    display: inline-block;
    margin: 0;
    box-sizing: border-box;
    vertical-align: middle;
    width: var(--size);
    height: var(--size);
    border: .1em solid;
    border-radius: 50%;
    border-right: .1em solid transparent;
    border-bottom: .1em solid var(--color);
    border-left: .1em solid var(--color);
    border-top: .1em solid var(--color);
    animation: spinner .75s linear infinite;
  }
  
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .statusIcon {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

  .statusIcon:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .deviceType {
    background-color: rgb(240, 242, 247);
    padding: 8px;
    border-radius: 4px;
    margin: 6px 4px 6px 0px;
    width: fit-content;
  }

  .caTableContainer {
    overflow-y: scroll;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: white;
    height: 501px;
  }