.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 25%;
  box-shadow: 3px 0px rgb(0 0 0 / 10%);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.container::-webkit-scrollbar {
  display: none;
}
.button {
  height: 48px;
  border: none;
  border-bottom: #f0f2f7 solid 1px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.button:hover {
  background-color: whitesmoke;
}
.selected {
  background-color: #f0f2f7;
}
.selected:hover {
  background-color: #f0f2f7;
}
.length {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lengthAll {
  max-width: 80%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2%;
}
.nameAll {
  display: flex;
  width: 15%;
  text-align: start;
  font-size: 13px;
  height: 48px;
  align-items: center;
  background-color: inherit;
  cursor: pointer;
  border-bottom: 1px solid #f0f2f7;
  justify-content: center;
}
.name {
  width: 70%;
  text-align: start;
  font-size: 13px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select {
  color: rgb(205, 212, 228);
  font-size: 13px;
  height: 25px !important;
  width: 25px !important;
  cursor: pointer;
}
.select:hover {
  background-color: #ebebeb;
  border-radius: 50%;
}
