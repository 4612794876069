.customScrollbar {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .scrollContainer {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
  }
  
  .horizontalSliderContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: pointer;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .horizontalSlider {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  
  .horizontalSlider:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .horizontalSlider:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .verticalSliderContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    cursor: pointer;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .verticalSlider {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  
  .verticalSlider:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .verticalSlider:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  