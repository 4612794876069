 .tab-list {
    padding-left: 0;
    margin-top: 0px;
    display: flex;
    background-color: rgba(205,212,228,.1803921568627451)
  }
  
  .tab-list-item {
    display: flex;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    flex-direction: row;
    align-items: center;
    max-width: 500px;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
  }
  
  .tab-list-active {
    font-weight: bold;
    
  }

  .close-btn {
    margin-left: 10px;
    font-weight: bold;
    color: gray;
    cursor: pointer;
  }
  .new-btn {
    margin-left: 10px;
    font-weight: bold;
    color: gray;
    cursor: pointer;
    font-size: 35px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-content{
    margin-left: 2%;
    margin-right: 2%;
  }
  .tab-content.height{
    height: 300px;
    /* overflow: auto; */
  }
  .tabs.left{
    background-color: #ffffff;
    border: 2px solid rgba(205,212,228,.1803921568627451);
    margin-right: 2px;
  }
  .tab-list.center{
    width: 100%;
    display: flex;
    justify-content: center;
  }
