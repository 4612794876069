.userlist-container {
  width: 75%;
  background-color: #f0f2f7;
  margin-left: 2px;
  position: relative;
}

.userlist-container::-webkit-scrollbar {
  display: none;
}

.userlist-table-container {
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  height: 500px;
}

.userlist-table-container::-webkit-scrollbar {
  display: none;
}
