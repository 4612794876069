.mainWrapper{
    padding-top: 48px;
    padding-bottom: 12px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}
  
.afModuleSubTitle {
    color: #676767;
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 2px);
    line-height: calc(var(--spacer) * 3px);
    margin-block-end: calc(var(--spacer) * 0px);
    margin-block-start: calc(var(--spacer) * 0px);
    text-align: center;
    padding-top: 16px;
}

.formContent{
    width: 50%;
    margin: 0 auto calc(var(--spacer)*8px) auto;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    /* height:400px; */
    overflow: hidden;
    overflow-y:scroll;
    text-align: left;
}
.formField{
    width: 100%;
    margin-top: 8px;
}

.radioField{
    display: flex;
    align-items: flex-start;
}

.radio{
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: 5px !important; /*18*/
    -webkit-tap-highlight-color: transparent;
}

.radioDesc{
    color: #8C8C8C;
    font-size: 13px;
    line-height: 24px;
    margin-top: -15px;
    margin-bottom: 0px;
    padding-left: 9px;
    padding-bottom: 0px;
    background-color: transparent;
}

.radioTitle{
    padding: 0px;
    color: #8C8C8C;
    font-size: 13px;
    line-height: 24px;
    padding-left: 9px;
    padding-bottom: 0px;
    margin-top: 0px;
    background-color: transparent;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgb(240, 242, 247);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.checkboxWrapper{
    display: flex;
    justify-content: space-between;
}

.checkboxField{
    display: flex;
    align-items: center;
}

.showMoreLink {
    text-decoration: none;
    color: var(--secondaryTextColor);
}

.checkbox {
    margin-top: -1px;
}