.databaseCon {
  display: flex;
  flex-direction: row;
  /* min-height: calc(93vh - var(--spacer) * 8px); */
  height: calc(100vh - var(--spacer) * 14px);
  background-color: #f0f2f7;
  font-size: 14px;
}

.afModule {
  padding-left: 0px;
  width: 20%;
  background-color: #ffffff;
  /* height: 100%; */
}

.gridCon {
  width: 100%;
  background-color: white;
  overflow: scroll;
}

.gridInnerCon {
  width: 100%;
}

.propertiesCon {
  width: 20%;
  border-left: 1px solid lightgray;
  padding-left: 10px;
}

.addButton {
  width: 80% !important;
  margin: 10px;
  right: 4%;
}

.tree {
  /* height: 80%; */
  /* height: 59vh; /*reduced from 65vh */
  height: calc(100vh - var(--spacer) * 30px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 15vw;
}

.tree::-webkit-scrollbar {
  display: none;
}

.tabs {
  display: table;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.tabItem {
  float: left;
  padding: 10px;
  background-color: lightgray;
}

.databaseHeader {
  font-family: Saira;
  font-weight: normal;
  line-height: 40px;
}

.gridHeader {
  display: flex;
  padding: 10px 0px 10px 20px;
  justify-content: space-between;
}

.databaseInnerCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.inputs {
  background-color: #f0f2f7;
  border: none;
  height: 30px;
  margin: 5px 0px 0px 10px;
}

.textarea {
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  border: 2px solid rgba(205, 212, 228, 0.1803921568627451);
  min-height: 50px;
  text-indent: 5px;
  margin-bottom: 10px;
  outline: none;
  padding: 5px;
  min-width: 150px;
}

.submitContainer {
  /* margin: 50px 0px 10px;  */
  text-align: center;
}

.executebtn {
  float: "right";
  margin-bottom: 10px;
}

.tableLabel {
  font-weight: bold;
  font-size: 18px;
  border: none;
  box-shadow: none;
  outline: none;
  width: 330px;

}

.afFooter {
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  height: calc(var(--spacer) * 9px);
  background-color: var(--backgroundColor);
  padding: 0px calc(var(--spacer) * 1.5px);
  width: calc(100% - (var(--spacer) * 6px));
  border-top: 1px solid var(--pageBackground);
}

.popupTitle {
  margin-top: calc(var(--spacer) * 2px);
}

.formArea {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: calc(var(--spacer) * 5px) 0px;
}

.mappingColumnsData {
  display: flex;
  flex-direction: row;
}

.tableColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(var(--spacer) * 1px) 0px;
}

.mappingData {
  margin: calc(var(--spacer) * 1px);
  width: calc(var(--spacer) * 28px);
}

.dbColumnValues {
  opacity: 1;
}

.dbColumnHeader {
  overflow: scroll;
  /* to handle values morethan container width */
  display: flex;
  align-items: center;
  background-color: #da1e2842;
  border: 1px solid #da1e2842;
  height: calc(var(--spacer) * 6px);
  padding: 0px calc(var(--spacer) * 2px);
}

.dbColumnHeaderShade {
  background-color: rgba(205, 212, 228, 0.25);
  border: 1px solid rgba(205, 212, 228, 0.25);
}

.dbColumnValue {
  overflow: scroll;
  /* to handle values morethan container width */
  display: flex;
  align-items: center;
  border: 1px solid #da1e2842;
  height: calc(var(--spacer) * 6px);
  padding: 0px calc(var(--spacer) * 2px);
}

.right {
  float: right;
}

.columnContainer {
  margin-top: 10px;
  /* height: 215px; 300px */
  height: calc(100vh - var(--spacer) * 50px);
  overflow: auto
}

.alterTableContentStyle {
  height: calc(100vh - var(--spacer) * 42px);
}

.columnButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 80vw;
  flex-wrap: wrap;
}

.queryDownloadButton {
  justify-self: flex-start;
}

.addButtonContainer {
  height: 20%;
}

.logContainer {
  white-space: pre-wrap;
  padding-left: 30px;
  line-height: 1.2em;
}

.databaseQueryTable {
  margin-top: 15px;
  /* max-height: 230px; 300px */
  height: calc(100vh - var(--spacer) * 45px);
  overflow: auto !important;
  max-width: 1300px;
}

.databaseQueryTable::-webkit-scrollbar:horizontal {
  width: 10px !important;
  height: 10px !important;
}

.databaseQueryTable::-webkit-scrollbar-track:horizontal {
  background-color: #f1f1f1;

}

.databaseQueryTable::-webkit-scrollbar-thumb:horizontal {
  background-color: #888;
  border-radius: 5px;
}