#blocklyDiv {
  /* height: 500px; */
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 40px;
  z-index: -1;
  margin-top: 120px;
}
.blocklyToolboxDiv{
  top: 0px;
  width: 312px;
  height: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}
.blocklyToolboxCategory{
  border-bottom: 1px solid #ddd;
  height: 40px;
  width: 312px;
}
.blocklyFlyout{
  transform: translate(0px, 0px) !important;
  width: 312px !important;
  background: #FFFFFF;
  top: -2px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.08) !important;
  height: calc(100vh + 31px);
}
.blocklyDropdownRect{
  rx:17;
  ry:17;
}
.blocklyFlyoutBackground{
  fill: #fff;
}
.blocklyScrollbarVertical{
  visibility: hidden;
}
/* .blocklyScrollbarHorizontal, .blocklyScrollbarVertical{
  background-color: #ddd;
} */
.blocklySvg{
  background-color: #ddd;
}
.blocklyTreeLabel{
  font-size: 13px;
  font-family: Saira;
  font-weight: 400;
  line-height: 1.5;
  padding-left: 28px;
  height: 40px;
  width: 280px;
  padding-top: 8px;
}
.blocklyTreeRowContentContainer{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.blocklyTreeSelected{
  height: 40px;
  background-color: unset !important;
}
.blocklyTreeSelected .blocklyTreeLabel{
  color: #000000;
}
.blocklyToolboxCategory:hover{
  cursor: pointer;
}
/* .blocklyToolboxCategory:nth-child(1){
  background-image: url('../../../assets/images/jsbuilder/panel_icons/flow.png');
  background-repeat: no-repeat;
  background-position: left 5px top 10px;
  background-size: 15px 17px;
} */

.buttonStyle{
  /* fill: red; */
  width: 445px !important;
  height: 50px !important;
  x: -16px !important;
  y: -9px !important;
  /* outline-style: auto; */
  stroke-dasharray:455 1000; 
  stroke:#CDD4E4 ;
  stroke-width:1; 
  stroke-dashoffset:-494;
  cursor: pointer;
  rx: 0 !important;
}
.blocklyFlyoutButtonBackground{
  /* width: 445px !important;
  height: 54px !important;
  x: -16px !important;
  y: -9px !important; */
  width: 447px !important;
  height: 54px !important;
  x: -16px !important;
  y: -9px !important;
}
.blocklyText{
  fill: black !important;
  font-weight: normal !important;
  font-family: 'Saira' !important;
  font-size: 13pt !important;
  cursor: pointer;
  x: -16px !important;
  y: -9px !important;
}
.blocklyFlyoutButton{
  fill: #F5F6FA;
}

.disableToolbox{
  pointer-events: none;
}

.blocklyFlyoutScrollbar{
  transform: translate(300px, 2.5px) !important;
}

.buttonStyle:hover{
  fill: #97C1FF !important;
}
.create-var-button-style:hover{
  fill: #97C1FF !important;
}

.blocklyMainBackground{
  stroke: none;
}
.injectionDiv {
  overflow: scroll !important;
}

.create-var-button-style{
  /* fill: red; */
  width: 445px !important;
  height: 50px !important;
  x: -16px !important;
  y: -9px !important;
  /* outline-style: auto; */
  stroke-dasharray:445 1000; 
  stroke: #B1A3FF ;
  stroke-width:1; 
  stroke-dashoffset:-494;
  cursor: pointer;
  rx: 0 !important;
}

.create-var-button-style .blocklyFlyoutButtonBackground{
  width: 200px !important;
  height: 40px !important;
  x: 2px !important;
  fill: #B1A3FF !important;
}

.create-var-button-style .blocklyFlyoutButtonShadow{
  width: 200px !important;
  fill: #B1A3FF !important;
}

/* .blocklyHtmlInput{
  visibility: hidden !important;
} */

.zelos-renderer.themeName-theme .blocklyHtmlInput,
.zelos-renderer.themeName-theme.blocklyWidgetDiv .goog-menuitem, 
.zelos-renderer.themeName-theme.blocklyDropDownDiv .goog-menuitem,
.zelos-renderer.themeName-theme.blocklyDropDownDiv .goog-menuitem,
.zelos-renderer.themeName-theme .blocklyText{
  font-family: 'Saira' !important;
  font-weight: normal !important;
}

.blocklyHtmlInput{
  color: black !important;
  background-color: white;
}

.hiddenBlock{
  visibility: hidden !important;
}

/* .zelos-renderer.themeName-theme.blocklyDropDownDiv .goog-menuitem-content{
  color: #000000 !important;
} */

.zelos-renderer.themeName-theme .blocklyDropdownText{
  fill: #000000 !important;
} 
/* .blocklyFlyoutButton:nth-child(1) .blocklyFlyoutButtonBackground{
  y: -15px !important;
}
.blocklyFlyoutButtonBackground:nth-child(4){
  y: -45px !important;
} */
/* .blocklyWorkspace > blocklyBlockCanvas > .buttonStyle:nth-child(3) .blocklyFlyoutButtonBackground{
  width: 100px !important;
  height: 20px !important;
} */

.blocklyScrollbarHorizontal{
  display: none;
}

.blocklyDropDownDiv{
  border-radius: 2px;
}

.blocklySearchInput{
  position: fixed;
  bottom: 0;
}

.blocklySearchInput .MuiFormGroup-root{
  width: 312px !important;
  background-color: rgba(240, 242, 247, 0.5);
  box-shadow: 0px -1px 0px #F0F2F7;
  /* box-shadow: none; */
}