.modify-popup-container {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.modify-popup-container:hover {
  background-color: #ebebeb;
  border-radius: 50%;
}
.modify-popup-container svg {
  cursor: pointer;
  color: #cdd4e4;
  width: 0.75em;
  height: 0.75em;
}
.modify-popup-container div {
  position: absolute;
  z-index: 100;
  background-color: white;
  top: 0px;
  right: 0px;
  transform: translate3d(-24px, 23px, 0px);
  will-change: transform;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 24%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}
.modify-popup-container span {
  padding: 0px 15px;
  box-sizing: border-box;
  font-family: Saira;
  font-weight: 400;
  line-height: 35px;
  white-space: nowrap;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
}
.modify-popup-container span:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: var(--activeBackgroundColor);
}
.moreVertIcon {
  width: 0.75em;
  height: 0.75em;
}
