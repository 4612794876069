.btn-add-scheduler{
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduler-table{
    width: 80vw;
    margin: auto;
}

.scheduler-pagination{
    width: 80vw;
    margin: auto;
}

.scheduler{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--pageBackground);
    /* min-height: calc(100vh - var(--spacer) * 8px); */
    padding: calc(4px * var(--spacer)) calc(1% * var(--spacer));
}