@font-face {
  font-family: 'Saira';
  src: url('./fonts/Saira-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Saira';
  src: url('./fonts/Saira-Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-family: 'Saira';
  src: url('./fonts/Saira-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Saira';
  src: url('./fonts/Saira-Regular.ttf');
  font-weight: normal;
}

:root {
  --fontName: 'Saira';
  --pageBackground: #f0f2f7;
  --backgroundColor: #ffffff;
  --navbarBackgroundColor: #0062ff;
  --legendColor: #676767;
  --inputBorderColor: #cdd4e4;
  --primaryColor: #0062ff;
  --secondaryColor: white;
  --highlightColor: #97c1ff;
  --textColor: #000000;
  --primaryTextColor: white;
  --secondaryTextColor: #0062ff;
  --placeHolderColor: #cdd4e4;
  --borderRadius: 8px;
  --inputBorderRadius: 2px;
  --labelFontSize: 13px;
  --labelLineHeight: 24px;
  --labelHeight: 24px;
  --inputHeight: 40px;
  --textAreaHeight: 120px;
  --inputBackground: #f0f2f7;
  --shadowColor: 0px 4px 12px rgba(0, 0, 0, 0.08);
  --buttonShadowColor: 0px 2px 4px rgba(0, 0, 0, 0.08);
  --primaryHoverColor: rgb(0, 68, 178);
  --activeBackgroundColor: rgb(237, 244, 255);
  --spacer: 8;
}

body {
  margin: 0;
  /* height: 100vh; */
  box-sizing: border-box;
  background-color: var(--pageBackground);
  font-family: var(--fontName);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Container {
  box-shadow: var(--shadowColor);
  border-radius: var(--borderRadius);
  background-color: var(--backgroundColor);
}
.vspace {
  width: 100%;
  height: 8px;
}
button {
  font-family: var(--fontName);
}
.form input[type='submit'],
.form input[type='button'] {
  width: 60px;
  border-width: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 2px;
  font-family: var(--fontName);
  color: var(--primaryTextColor);
  height: calc(var(--spacer) * 4px);
  background-color: var(--primaryColor);
  box-shadow: var(--buttonShadowColor);
}
.primary,
.secondary,
.tertiary {
  cursor: pointer;
  border-width: 0;
  border-radius: 2px;
  width: auto !important;
  font-family: var(--fontName);
  height: calc(var(--spacer) * 5px);
  box-shadow: var(--buttonShadowColor);
  color: var(--primaryColor) !important;
  margin: 0px calc(var(--spacer) * 1px);
  background-color: var(--secondaryColor) !important;
  padding: calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px);
}
.primary {
  color: var(--primaryTextColor) !important;
  background-color: var(--primaryColor) !important;
}
.tertiary {
  width: auto;
  margin: 0px calc(var(--spacer) * 1px);
  color: white !important;
  background-color: lightgray !important;
}
.primary:hover,
.form input[type='submit']:hover,
.form input[type='button']:hover {
  cursor: pointer;
  background-color: var(--primaryHoverColor);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.secondary:hover {
  background-color: rgba(0, 98, 255, 0.25);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
h1 {
  margin: 0;
  font-weight: normal;
  font-family: 'Saira';
  font-size: calc(var(--spacer) * 8px);
  line-height: calc(var(--spacer) * 8px);
}
h2 {
  margin: 0;
  font-size: 18px;
  line-height: calc(var(--spacer) * 5px);
}
.text {
  margin: 0;
  font-size: 15px;
  line-height: calc(var(--spacer) * 3px);
}
.bold {
  margin: 0;
  font-size: 18px;
  line-height: calc(var(--spacer) * 3px);
  font-weight: 500;
}
.errorMessage {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: red;
  height: 16px;
  font-size: calc(var(--spacer) * 1.5px);
  line-height: calc(var(--spacer) * 2px);
}
::placeholder {
  color: var(--placeHolderColor);
  opacity: 1;
}
.form label {
  display: block;
  font-size: var(--labelFontSize);
  line-height: var(--labelLineHeight);
  /* height: var(--labelHeight); */
}
.form input[type='url'],
.form input[type='text'],
.form input[type='number'],
.form input[type='password'],
.form input[type='email'],
.form input[type='url'] {
  box-sizing: border-box;
  height: var(--inputHeight);
  font-family: var(--fontName);
  font-size: var(--labelFontSize);
  background: var(--inputBackground);
  line-height: var(--labelLineHeight);
  padding-left: calc(var(--spacer) * 1px);
  border-radius: var(--inputBorderRadius);
  border: 1px solid var(--inputBorderColor);
}
.form input[type='checkbox'] { 
  width: auto;
}
.form input[type='radio'] {
  margin: 0px;
}
.form input[type='text'][data-error],
.form textarea[type='text'][data-error],
.form input[type='password'][data-error],
.form select[data-error] {
  border: 1px solid red;
}
.form input:disabled,
.form textarea:disabled,
.form select:disabled {
  opacity: 0.75;
}
input[type='submit']:disabled {
  opacity: 0.75;
}
.form select {
  width: auto;
  appearance: none;
  box-sizing: border-box;
  height: var(--inputHeight);
  font-family: var(--fontName);
  font-size: var(--labelFontSize);
  background: var(--inputBackground);
  line-height: var(--labelLineHeight);
  padding-left: calc(var(--spacer) * 1px);
  border-radius: var(--inputBorderRadius);
  border: 1px solid var(--inputBorderColor);
  padding-right: calc(var(--spacer) * 4px);
  background-image: url("data:image/svg+xml;utf8,<svg stroke='black' fill='none' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5'/></svg>");
  background-position-y: calc(var(--spacer) * 1px);
  background-position-x: 100%;
  background-repeat: no-repeat;
}
.form textarea {
  width: 100%;
  resize: none;
  appearance: none;
  box-sizing: border-box;
  font-family: var(--fontName);
  height: var(--textAreaHeight);
  font-size: var(--labelFontSize);
  background: var(--inputBackground);
  line-height: var(--labelLineHeight);
  padding-left: calc(var(--spacer) * 1px);
  padding-right: calc(var(--spacer) * 4px);
  border: 1px solid var(--inputBorderColor);
  border-radius: var(--inputBorderRadius);
}
.column {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
.navbar {
  color: white;
  display: flex;
  align-items: center;
  gap: calc(var(--spacer) * 1px);
  height: calc(var(--spacer) * 8px);
  padding: calc(var(--spacer) * 1px);
  background-color: var(--navbarBackgroundColor);
}
.navbar a {
  text-decoration: none;
  color: var(--primaryTextColor);
}

/* Margin*/
.mg1 {
  margin: calc(var(--spacer) * 1px);
}
.mg2 {
  margin: calc(var(--spacer) * 2px);
}
.mg3 {
  margin: calc(var(--spacer) * 3px);
}
.mg4 {
  margin: calc(var(--spacer) * 4px);
}

/* Margin Left */
.ml1 {
  margin-left: calc(var(--spacer) * 1px);
}
.ml2 {
  margin-left: calc(var(--spacer) * 2px);
}
.ml3 {
  margin-left: calc(var(--spacer) * 3px);
}
.ml4 {
  margin-left: calc(var(--spacer) * 4px);
}

/* Margin Right */
.mr1 {
  margin-right: calc(var(--spacer) * 1px);
}
.mr2 {
  margin-right: calc(var(--spacer) * 2px);
}
.mr3 {
  margin-right: calc(var(--spacer) * 3px);
}
.mr4 {
  margin-right: calc(var(--spacer) * 4px);
}

/* Margin Bottom */
.mb1 {
  margin-bottom: calc(var(--spacer) * 1px);
}
.mb2 {
  margin-bottom: calc(var(--spacer) * 2px);
}
.mb3 {
  margin-bottom: calc(var(--spacer) * 3px);
}
.mb4 {
  margin-bottom: calc(var(--spacer) * 4px);
}

/* Margin Top */
.mt1 {
  margin-top: calc(var(--spacer) * 1px);
}
.mt2 {
  margin-top: calc(var(--spacer) * 2px);
}
.mt3 {
  margin-top: calc(var(--spacer) * 3px);
}
.mt4 {
  margin-top: calc(var(--spacer) * 4px);
}

.afModule {
  background-color: var(--pageBackground);
  min-height: calc(100vh - var(--spacer) * 8px);
  padding: calc(4px * var(--spacer)) calc(1% * var(--spacer));
}

.afError {
  color: red;
}

.primaryText {
  color: var(--primaryColor);
}

.emptyArena {
  width: 100%;
  height: 100px;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.afPopupFooter {
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  height: calc(var(--spacer) * 9px);
  background-color: var(--backgroundColor);
  padding: 0px calc(var(--spacer) * 1.5px);
  width: calc(100% - (var(--spacer) * 6px));
  border-top: 1px solid var(--pageBackground);
}
