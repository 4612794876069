.tableWrapper{
    width:80%;
    margin: 16px auto calc(var(--spacer)*8px) auto;
    height:400px;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
    border-radius: 8px;
    overflow: hidden;
    overflow-y:scroll;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}