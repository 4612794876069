.tabelAlign{
    border-radius: 8px;
    background-color: rgb(245, 246, 250);
    width: 60%;
    margin-top: 20px;
}

.tableWrapper{
    width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
}

.mainWrapper{
    width: 100%;
    height: 400px;
}