.formContent{
    width: 50%;
    margin: 0 auto calc(var(--spacer)*8px) auto;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height:400px; */
    overflow: hidden;
    overflow-y:scroll;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}
  
.formField{
    width: 100%;
    margin-top: 0px;
}

.formFieldVaried{
     width: 100%;
    margin-top: 0px;
    display: flex;
    /* justify-content: space-between; */
    column-gap: 20px;
}

.iconDiv{
    width: 100%;
    border: 1px dashed #CDD4E4;
    height: 136px;
    display: flex;
    padding: 2%;
    background: #F0F2F7;
    box-sizing: border-box;
    border-radius: 8px;
}

/* .iconDisplayDiv{
    border: 1px solid black;
} */

.iconImgCont{
    width: 80px;
    height: 80px;
    margin: 16px 77px 0px 16px;
    border-radius: 16px;
    background:none;
}

.iconCont{
    margin:16px 77px 0px 16px;
    width:100%;
}

.iconImgFillCont{
    background: rgb(156, 99, 249);
    width: 80px;
    height: 80px;
    margin: 16px 77px 0px 16px;
    border-radius: 16px;
}

.resolutionText{
    margin-left: 30px;
    color: var(--legendColor);
}

.uploadFileText{
    color: var(--legendColor);
}

.iconUploadDiv{
    justify-content: center;
    text-align: center;
}

.img{
    border-radius: 16px;
}