:root {
    --light-color: #FFFFFF;
    --background-color: #F0F2F7;
    --pillir-primary: #0062FF;

    --pillir-label-background: #F5F6FA;
    --pillir-text-primary: #000000;
    --pillir-coolgray10: #F0F2F7;
    --pillir-coolgray60: #676767;
    --pillir-variable: #E2D0FF;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-center {
    justify-content: center !important;
}

.d-grid {
    display: grid !important;
}

/* Properties */
#propertieMenu {
  width: 272px;
  height: 100%;
}
#properties {
  background: #fff;
  border-left: 1px solid #ddd;
}
.properties {
  height: 100%;
  overflow: auto;
}
.properties {
  width: 272px;
}

.container_header {
  display: flex;
  padding: 10px 20px 10px;
  background-color: var(--pillir-label-background);
}

#properties input[type='text'], #properties textarea, #properties select {
  border-radius: 0px;
}

#bos_name {
  margin-left: 5px;
}
input[type="checkbox"] {
  margin: 0px;
}

.container_body {
  padding: 1rem;
}

div {
  display: block;
}

div.variable {
  display: inline-block;
  min-height: 20px;
  min-width: 85px;
  vertical-align: middle;
  border-radius: 24px 24px;
  background-color: var(--pillir-variable);
  text-align: center;
  padding: 2px 4px;
}

div.variable img {
  width: 10px;
  height: 10px;
  margin: 0 4px;
}

.container_body {
  padding: 20px;
}

div.input-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.container_header label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearfix {
	*zoom: 1;
}

.clearfix:before,.clearfix:after {
	display: table;
	line-height: 0;
	content: "";
}

.clearfix:after {
	clear: both;
}

.dropdown-menu
	{
	font-size: 12px;
}
.input-append .add-on,.input-prepend .add-on,.input-append .btn,.input-prepend .btn,.input-append .btn-group>.dropdown-toggle,.input-prepend .btn-group>.dropdown-toggle
	{
	vertical-align: top;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.input-append .add-on:last-child,.input-append .btn:last-child,.input-append .btn-group:last-child>.dropdown-toggle
	{
	-webkit-border-radius: 0 4px 4px 0;
	-moz-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.icon-white,.nav-pills>.active>a>[class^="icon-"],.nav-pills>.active>a>[class*=" icon-"],.nav-list>.active>a>[class^="icon-"],.nav-list>.active>a>[class*=" icon-"],.navbar-inverse .nav>.active>a>[class^="icon-"],.navbar-inverse .nav>.active>a>[class*=" icon-"],.dropdown-menu>li>a:hover>[class^="icon-"],.dropdown-menu>li>a:focus>[class^="icon-"],.dropdown-menu>li>a:hover>[class*=" icon-"],.dropdown-menu>li>a:focus>[class*=" icon-"],.dropdown-menu>.active>a>[class^="icon-"],.dropdown-menu>.active>a>[class*=" icon-"],.dropdown-submenu:hover>a>[class^="icon-"],.dropdown-submenu:focus>a>[class^="icon-"],.dropdown-submenu:hover>a>[class*=" icon-"],.dropdown-submenu:focus>a>[class*=" icon-"]
	{
	background-image: url("../images/glyphicons-halflings-white.png");
}

.dropup,.dropdown {
	position: absolute;
}

.dropdown-toggle {
	*margin-bottom: -3px;
}

.dropdown-toggle:active,.open .dropdown-toggle {
	outline: 0;
}

.dropdown .caret {
	margin-top: 8px;
	margin-left: 2px;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	background-color: #ffffff;
	/* border-right-width: 2px;
	border-bottom-width: 2px; */
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

.dropdown-menu.pull-right {
	right: 0;
	left: auto;
}

.dropdown-menu .divider {
	width: 100%;
	height: 1px;
	margin: 9px 1px;
	margin: -5px 0 5px;
	overflow: hidden;
	background-color: #e5e5e5;
	border-bottom: 1px solid #bbbbbb;
}

.dropdown-menu>li>a {
	display: block;
	padding: 3px 16px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #000000;
	white-space: nowrap;
  text-decoration: none;
  font-size:14px;
  font-family: Saira;
}

.dropdown-menu>li{
	margin-top: 8px;
	margin-bottom: 8px;
}

.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus,.dropdown-submenu:hover>a,.dropdown-submenu:focus>a
	{
	color: #ffffff;
	text-decoration: none;
	background-color: #17649A;

}

.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus
	{
	color: #ffffff;
	text-decoration: none;
	background-color: #6cb250;
	background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc),
		to(#0077b3));
	background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
	background-image: -o-linear-gradient(top, #0088cc, #0077b3);
	background-image: linear-gradient(to bottom, #0088cc, #0077b3);
	background-repeat: repeat-x;
	outline: 0;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc',
		endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:hover,.dropdown-menu>.disabled>a:focus
	{
	color: #999999;
}

.dropdown-menu>.disabled>a:hover,.dropdown-menu>.disabled>a:focus {
	text-decoration: none;
	cursor: default;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open {
	*z-index: 1000;
}

.open>.dropdown-menu {
	display: block;
}

.pull-right>.dropdown-menu {
	right: 0;
	left: auto;
}

.dropup .caret,.navbar-fixed-bottom .dropdown .caret {
	border-top: 0;
	border-bottom: 4px solid #000000;
	content: "";
}

.dropup .dropdown-menu,.navbar-fixed-bottom .dropdown .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-bottom: 1px;
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px 6px;
	border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
	display: block;
}

.dropup .dropdown-submenu>.dropdown-menu {
	top: auto;
	bottom: 0;
	margin-top: 0;
	margin-bottom: -2px;
	-webkit-border-radius: 5px 5px 5px 0;
	-moz-border-radius: 5px 5px 5px 0;
	border-radius: 5px 5px 5px 0;
}

.dropdown-submenu>a:after {
	display: block;
	float: right;
	width: 0;
	height: 0;
	margin-top: 5px;
	margin-right: -10px;
	border-color: transparent;
	border-left-color: #cccccc;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	content: " ";
}

.dropdown-submenu:hover>a:after {
	border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.dropdown .dropdown-menu .nav-header {
	padding-right: 20px;
	padding-left: 20px;
}

.btn-group>.btn,.btn-group>.dropdown-menu,.btn-group>.popover {
	font-size: 12px;
}

div.dropdownContainer {
height: 320px;
width: 192px;
background-color: var(--light-color);
position: absolute;
}

div.dropdownContainer div.dropdownSearch input {
width: 100%;
height: 32px;
background-color: var(--light-color);
border: none;
outline: none;
border-bottom: 1px solid var(--pillir-coolgray10);
}

div.dropdownContainer div.dropdownSearch img {
position: absolute;
right: 10px;
top: 8px;
}

div.dropdownContainer div.dropdownItems {
height: 100%;
overflow: scroll;
}

div.dropdownContainer div.dropdownItems ul {
height: 100%;
}

div.dropdownContainer div.dropdownItems ul li {
height: 32px;
padding: 8px;
}

#dropdownlist,
.dropdownItems ul{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}

#dropdownlist .dropdownItems ul {
	display: flex;
    flex-direction: column;
}

#dropdownlist .dropdownItems ul li {
	display: flex !important;
    flex-direction: row-reverse !important;
	justify-content: flex-end !important;
	margin-left: 0;
}

#dropdownlist .dropdownItems ul li img{
	position: relative;
	top: 2px;
	margin-right: 8px;
}

#contextMenu .dropdown-menu{
	height: 320px;
	overflow-y: scroll;
}