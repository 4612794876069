/* Remove default bullets */
.rootT {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  line-height: 25px;
  flex-direction: row;
  justify-content: space-between;
}

.rootIcon {
  /* position: absolute;
  right: 10px; */
  color: lightgray;
}

/* Style the caret/arrow */
.activeRoot {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
  background-color: rgba(0, 0, 0, 0.08);
}

/* Hide the nested list */
.nestedNode {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.activeLeaf {
  display: block;
}

.span_open{
  display: flex;
  align-items: center;
  width:75%;
  overflow: hidden;
  /* padding-left: 30px; */
  cursor: pointer;
}

.workspaceTableT{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor:pointer;
  padding-left: 0px;
}

.leafnode{
  display: flex;
  align-items: center;
  width:100%;
  overflow: hidden;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.activeNode {
  display: block;
}