.afModuleSubTitle {
    /* color: #676767; */
    color: rgba(0, 0, 0, 0.87);
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 1.75px);
    line-height: calc(var(--spacer) * 3px);
    margin-block-end: calc(var(--spacer) * 0px);
    margin-block-start: calc(var(--spacer) * 0px);
    text-align: center;
    padding-top: 16px;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}

.formContent{
    width: 75%;
    margin: 0 auto calc(var(--spacer)*8px) auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height:400px; */
    box-sizing: inherit;
    overflow: hidden;
    overflow-y:scroll;
    padding: 8px 24px;
}

::-webkit-scrollbar {display:none;}
.formField{
    width: 100%;
    margin-top: 8px;
}

.tableWrapper{
     width: 100%;
    /* margin: 0px auto calc(var(--spacer)*8px) auto; */
    height:400px;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
    font-size: 14px;
    border-radius: 8px;
    overflow: hidden;
    overflow-y:scroll;
}

.formFieldVaried{
  width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.universalWorkList{
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    gap:2%;
}