.afModuleSubTitleVaried{
    /* color: #676767; */
    color: black;
    font-family: Saira;
    font-weight: normal;
    /* font-size: calc(var(--spacer) * 2px); */
    font-size: 13px;
    line-height: calc(var(--spacer) * 3px);
    margin-block-end: calc(var(--spacer) * 0px);
    margin-block-start: calc(var(--spacer) * 0px);
    /* padding-top: 16px;
    padding-bottom: 16px; */
    margin-bottom: 9px;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}

.formContent{
    margin: 0 auto calc(var(--spacer)*8px) auto;
    display: flex;
    flex-direction: column;
    /* height:400px; */
    overflow: hidden;
    overflow-y:scroll;
    /* overflow:scroll; */
}
.formField{
    width: 100%;
    margin-top: 8px;
}

.individualContent{
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: row;
    margin-bottom: 10px;
    gap:5px;
    /* width: 50%; */
    width:100%;
}

.individualContentVaried{
     display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* margin-bottom: 10px */
}

.checkBoxContent{
      display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.checkboxWrapper{
    display: flex;
    justify-content: space-between;
}

.checkboxField{
    display: flex;
    align-items: center;
}

.contentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.contents{
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 12px;

    /* max-width: 100%;
    flex-basis: 100%;
    height: 365px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; */
}

.addLink{
    width: fit-content !important;
    padding: calc(var(--spacer) * 1px) calc(var(--spacer) * 1px);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 14%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 8%);
    /* margin: 10px 0; */
}

.deleteLink{
    border-radius: 50%;
    margin:0px;
    padding:calc(var(--spacer) * 1px) calc(var(--spacer) * 1px);
    box-shadow: none;
}