.outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.innerContainer {
  width: 50%;
  overflow: hidden;
  overflow-y: auto;
  height: 400px;
}

.innerContainer::-webkit-scrollbar {
  display: none;
}

.con {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
  gap: 36px;
  margin-right: 6%;
}

.cancel {
  background-color: white;
  width: 8%;
  padding: 0 0 0 8px;
  color: blue;
  border: none;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 2px !important;
}
