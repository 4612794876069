.submitContainer {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  padding-right: 10px;
  border-top: 2px solid #f0f2f7;
}

.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 70vh;
  margin-top: 30px;
}
.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 70%;
}
.formCon {
  width: 60%;
}
.afHeader {
  color: #000000;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin: 0;
}
.afSubHeader {
  text-align: center;
  font-size: 16px;
}
.inputStyle {
  width: 100% !important;
}
.typeContainer {
  display: flex;
  justify-content: space-around;
  height: 70vh;
  
}
.typeInnerCon {
  width: 40%;
  height: 55vh;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.image {
  height: 150px;
  width: 350px;
}
.bfContainer {
  height: 70vh;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.margin {
  margin-top: 70px;
}
.createCon {
  margin-top: 30px;
}
.abapContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  margin-top: 30px;
}
.abapTable::-webkit-scrollbar {
  display: none;
}
.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 70vh;
  margin-top: 30px;
}
.abapInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 100%;
}
.abapTable {
  overflow: scroll;
  height: 65vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.newSchedulerHeader{
  font-size: 3rem;
  text-align: center;
  
}

