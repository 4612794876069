.afCard {
  cursor: pointer;
  padding: 0px 0px 0px 0px;
  margin: calc(var(--spacer) * 1.5px);
  height: calc(var(--spacer) * 29px);
  width: calc(var(--spacer) * 36.25px);
  background-color: var(--backgroundColor);
  border-radius: calc(var(--spacer) * 1px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

.loading {
  box-shadow: none;
  background-color: var(--backgroundColor);
}

.loading > div {
  display: none;
}

.afCardImagePreviewArea {
  position: relative;
  background-color: #d2d8e7;
  height: calc(var(--spacer) * 22px);
  border-top-left-radius: calc(var(--spacer) * 1px);
  border-top-right-radius: calc(var(--spacer) * 1px);
}

.afCardImagePreviewArea > img {
  top: 0px;
  position: absolute;
  background-color: transparent !important;
  width: calc(var(--spacer) * 36.25px) !important;
  height: calc(var(--spacer) * 22px) !important;
}

.afCardImagePreviewArea > svg {
  top: 0px;
  position: absolute;
  background-color: transparent !important;
  min-width: calc(var(--spacer) * 121px) !important;
  min-height: calc(var(--spacer) * 73px) !important;
}

.afHoverList {
  top: 0px;
  display: none;
  z-index: 97;
  position: absolute;
  background-color: #9fadcb85;
  color: var(--primaryTextColor);
  height: calc(var(--spacer) * 22px);
  width: calc(var(--spacer) * 36.25px);
  border-top-left-radius: calc(var(--spacer) * 1px);
  border-top-right-radius: calc(var(--spacer) * 1px);
}

.afHoverRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  justify-content: space-between;
  height: calc(var(--spacer) * 4.5px);
  border-bottom: 1px solid #f0f2f773;
  padding: 0px calc(var(--spacer) * 1px);
  font-size: calc(var(--spacer) * 1.7px);
}

.afHoverOpenRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  justify-content: space-between;
  height: calc(var(--spacer) * 7px);
  border-bottom: 1px solid #f0f2f773;
  padding: 0px calc(var(--spacer) * 1px);
  font-size: calc(var(--spacer) * 1.7px);
}

.afCardImagePreviewArea:hover > .afHoverList {
  display: block;
}

.afContentArea {
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--spacer) * 6px);
  padding: calc(var(--spacer) * 0.5px) calc(var(--spacer) * 2px);
}

.afCardTitle {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(var(--spacer) * 2px);
  line-height: calc(var(--spacer) * 2.5px);
}

.afCardSubTitle {
  font-weight: 500;
  overflow: hidden;
  color: #A4A4A4;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(var(--spacer) * 1.375px);
}

.afRowText {
  width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}