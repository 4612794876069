.userprop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  background-color: #f0f2f7;
}

.userprop-top-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
}

.userprop {
  display: flex;
  justify-content: space-between;
}

.userprop button {
  cursor: pointer;
  height: 40px;
  padding: 0px 16px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 2px !important;
  border: none;
  color: #0062ff;
  background-color: white;
  margin-left: 10px;
}

.userprop-data-container {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 8px;
  overflow: inherit;
  display: flex;
  width: 90%;
}
