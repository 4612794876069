.assets-text {
  font-size: 64px;
  font-family: Saira;
  font-weight: normal;
  line-height: 64px;
}
.header-column {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 48%;
  margin-top: 3%;
}
.btn-upload {
  color: #ffffff;
  background-color: #0062ff;
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 105px;
  box-shadow: none;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
}
.assets-container {
  background-color: #f0f2f7;
  margin-left: 2px;
  /* position: relative; */
}
.asset-table-container {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 8px;
  height: 500px;
  background-color: #f0f2f7;
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.asset-table-container::-webkit-scrollbar {
  display: none;
}
.main-container-import {
  display: flex;
  justify-content: center;
}
.second-colum {
  background-color: #f0f2f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130%;
  height: 78%;
  position: relative;
  right: 13%;
  top: 5%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: calc(var(--spacer) * 5px) 0px;
}
.first-column {
  display: flex;
  flex-direction: column;
  width: 114%;
  position: relative;
  right: 5%;
}
.text-drop {
  font-size: 13px;
  font-weight: 500;
}
.text-or {
  font-size: 13px;
  font-weight: 500;
  position: relative;
  top: 4%;
}
.text-js-files {
  font-size: 14px;
  color: #a4a4a4;
  position: relative;
  top: 14%;
}
.input-text {
  position: relative;
  top: 10%;
}

/* .adjust-table-width {
  /* display: flex;
  justify-content: center;
  overflow-x: hidden; */
  /* box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 8px; */
  /* position: relative;
  /* top: 4%; */
  /* left: 27%; */
  /* width: 400px;
  height: 336px;
  background-color: yellow;
} */
.adjust-table-width {
  width: 400px;
  height: 300px;
  background-color: yellow;
}

.adjust-table-innerwidth {
  width: 399px;
  height: 299px;
}

.adjust-table-width::-webkit-scrollbar {
  display: none;
}
.applyproject-btn {
  display: flex;
  justify-content: flex-end;
  gap: 3%;
  padding: 24px;
  border-top: 1px solid rgb(240, 242, 247);
}
.btn-cancel-apply {
  font-size: 14px;
  font-weight: 500;
  color: #0062ff;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
}
.btn-save-apply {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #0062ff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
}
.container-edit-details {
  height: 500px;
  overflow-x: hidden;
}
.container-edit-details::-webkit-scrollbar {
  display: none;
}
.createfile-btn {
  display: flex;
  position: relative;
  left: 115%;
}

.error-msg {
  position: absolute;
  top: 92%;
  right: 42%;
  color: #ff0a12;
  font-size: 13px;
  text-align: center;
  line-height: 24px;
}

.assets-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  background-color: #f0f2f7;
  padding-top: 20px;
}

.assets-container {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  width: 90%;
  border-radius: 8px;
  display: flex;
  height: 550px;
  overflow: hidden;
}

.assets-inner-container {
  width: 75%;
  background-color: #f0f2f7;
  margin-left: 2px;
  position: relative;
}
.assets-table-container {
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  height: 500px;
}