.afModuleSubTitleVaried{
    color: #676767;
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 2px);
    line-height: calc(var(--spacer) * 3px);
    margin-block-end: calc(var(--spacer) * 0px);
    margin-block-start: calc(var(--spacer) * 0px);
    /* padding-top: 16px; */
    margin-bottom: 9px;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}

.formContent{
    margin: 0 auto calc(var(--spacer)*8px) auto;
    display: flex;
    flex-direction: column;
    /* height:400px; */
    overflow: hidden;
    overflow-y:scroll;
}
.formField{
    width: 100%;
    margin-top: 8px;
}

.individualContent{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 16px;
    gap:5px;
    column-gap: 24px;
    
}

.individualContentVaried{
     display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* margin-bottom: 10px */
}

.contentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.contents{
    flex-grow: 0;
    max-width: 50%;
    padding:0 2%;
    flex-basis: 50%;
}

.iconDiv{
    min-width: 100%;
    border: 1px dashed #CDD4E4;
    height: 136px;
    display: flex;
    padding: 2%;
    background: #F0F2F7;
    box-sizing: border-box;
    border-radius: 8px;
}

.iconDisplayDiv{
    width:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.iconImgCont{
    /* width: 80px;
    height: 80px;
    margin: 16px 77px 0px 16px;
    border-radius: 16px; */
    background:none;
}

.resolutionText{
    margin-left: 30px;
    color: var(--legendColor);
}

.uploadFileText{
    color: var(--legendColor);
}

.iconUploadDiv{
    justify-content: center;
    text-align: center;
    position: relative;
}

.bottomText{
    height: 70px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.imgCont{
    justify-content: center;
    align-items: center;
    display: flex;
}

.uploadSplashScreenCont{
    width: 144px;
    border: 1px dashed #CDD4E4;
    height: 80px;
    display: flex;
    padding: 2%;
    background: #F0F2F7;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
}

.hide{
    opacity: 0.0;
    position: absolute;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
}