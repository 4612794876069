.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
  display: grid;
  align-items: center;
  justify-content: center;
}

.spinner {
  --size: 2rem;
  --color: blue;
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
  width: var(--size);
  height: var(--size);
  border: .25em solid;
  border-radius: 50%;
  border-right: .25em solid transparent;
  border-bottom: .25em solid var(--color);
  border-left: .25em solid var(--color);
  border-top: .25em solid var(--color);
  animation: spinner .75s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader{
  display:flex;
  min-height: 4rem;
  align-items: center;
  justify-content: center;
  min-width: 20rem;
  padding: 1rem;
}

.notifications {
  display: flex;
  position: fixed;
  z-index: 999999;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  left: calc(100vw - 5vw - (var(--spacer) * 34px));
}

.notification {
  display: flex;
  z-index: 999999;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(var(--spacer) * 34px);
  margin: calc(var(--spacer) * 0.5px);
  padding: calc(var(--spacer) * 1.5px);
  min-height: calc(var(--spacer) * 6px);
  background-color: var(--backgroundColor);
  border-radius: calc(var(--spacer) * 0.5px);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
}

.closer {
  cursor: pointer;
  padding: calc(var(--spacer) * 1px);
}

.notification > p {
  width: 80%;
  margin: 0px calc(var(--spacer) * 1px);
  font-size: calc(var(--spacer) * 1.75px);
  word-break: break-word;
  white-space: pre-wrap;
}

.notification > .s {
  fill: green;
  width: calc(var(--spacer) * 3px);
  height: calc(var(--spacer) * 3px);
  margin: calc(var(--spacer) * 1px);
}

.notification > .e {
  fill: red;
  width: calc(var(--spacer) * 3px);
  height: calc(var(--spacer) * 3px);
  margin: calc(var(--spacer) * 1px);
}

.notification > .w {
  fill: yellow;
  width: calc(var(--spacer) * 3px);
  height: calc(var(--spacer) * 3px);
  margin: calc(var(--spacer) * 1px);
}