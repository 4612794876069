.container {
  padding-left: 10px;
  width: 99%;
  position: relative;
  background-color: inherit;
  overflow: visible !important;
}

.container:hover {
  background-color: inherit;
}

.button {
  height: 40px;
  border: none;
  text-align: left;
  padding-left: 0px;
  /* background-color: inherit !important; */
  outline: none;
  min-width:100px;
  max-width:99%;
}

.opButtons {
  width: 99%;
  height: 40px;
  border: none;
  text-align: left;
  padding-left: 10px;
  background-color: white !important;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  z-index: inherit !important;
  overflow: visible !important;
}

.opButtons:hover {
  background-color: whitesmoke !important;
}

.opContainer {
  position: absolute;
  z-index: 99;
  width: 99%;
  top: 0px;
}
