.header {
  padding-left: 46px;
  padding-right: 46px;
}

.close-icon-div {
  display: flex;
  justify-content: flex-end;
}

.user-heading {
  color: #000000;
  font-size: 40px;
  line-height: 48px;
  background-color: #fff;
  text-align: center;
  margin: 0;
}

.user-form-wrapper {
  /* padding-left: 12px;
  padding-right: 12px; */
  margin-top: 24px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: top;
}

.use-form-container {
  box-sizing: inherit;
  width: 53%;
  padding: 8px 24px;
  overflow-y: auto;
  height: calc(100vh - 300px);
}

.use-form-container::-webkit-scrollbar {
  display: none;
}

.field-holder {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  justify-content: space-between;
  /* background-color: red; */
  margin-bottom: 16px;
}

.form-field {
  /* max-width: 50%;
  flex-basis: 50%; */
  min-width: 184px;
  display: flex;
  flex-direction: column;
}

.form-label {
  font-size: 15px;
  line-height: 32px;
  font-weight: normal;
}

.form-label-property{
   font-size: 15px;
  line-height: 32px;
  font-weight: normal;
  width: 45%;
}


.user-input {
  max-width: 184px;
  background-color: #f0f2f7;
  border: 0;
  padding: 8px;
}

.user-input-title {
  width: 100%;
}

.input-align {
  /* width: 80%; 
    height: 25px;
    background-color: #f0f2f7;
    border: none;
    margin-top: 5px; */
  width: 100%;
  height: 30px;
  background-color: #f0f2f7;
  border: none;
}

.input-align-varied {
  /* width: 80%; 
    height: 25px;
    background-color: #f0f2f7;
    border: none;
    margin-top: 5px; */
  width: 250px;
  height: 30px;
  background-color: #f0f2f7;
  border: none;
}

.form-select {
  height: 32px;
  font-size: 13px;
  padding: 0 24px 0 10px !important;
  width: 184px;
  background-color: #f0f2f7;
  border: 0;
}

.form-field-varied {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 8px;
  align-items: center;
}

.checkbox-field {
  display: flex;
  margin-bottom: 24px;
  margin-top: 8px;
  align-items: center;
}

.form-field-active{
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
  align-items: center;
}

.form-select-varied {
  width: 80%;
  border: 0;
}

.form-select-varied-hide {
  width: 240px;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(240, 242, 247);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.cancel {
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px;
  padding-right: 16px;
  text-transform: none;
  color: #0062ff;
  background-color: #ffffff;
  margin-right: 8px;
}

.save {
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
  color: #ffffff;
  background-color: #0062ff;
}

.userm-button {
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
  color: #0062ff;
  background-color: white;
  max-width: 138.087px;
}

.hide-show-field-holder {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
}

.select-align {
  width: 100%; 
  height: 30px;
  background-color: #f0f2f7;
  border: none;
}

.select-align-timezone {
  width: 100% !important;
  height: 30px;
  background-color: #f0f2f7;
  border: none;
}

.select-align-varied{
  width: 100% !important;
  height: 30px;
  background-color: #f0f2f7;
  border: none;
}

.select-align:focus {
  border: none;
}

.userm-button {
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 16px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
  color: #0062ff;
  background-color: white;
  max-width: 138.087px;
}