.mainWrapper{
    display:flex;
    height:400px;
    column-gap: calc(var(--spacer)*3px);
    align-items:center;
    justify-content:center;
}

.rowCont{
    display:flex;
    flex-direction: row;
    padding-bottom: 16px;
    align-items: center;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}

.numberCont{
    color:white;
    background-color: #CDD4E4;
    border-radius: 50%;
    width:24px;
    height:24px;
    text-align: center;
    margin-right: 8px;
}

.qrCodeCont{
    margin-top: 40px;
    max-width: 200px;
    max-height: 200px;
    height: "auto";
    width: "100%";
}

.logoCont{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5%;
}

.buttonCont{
   width: 200px;
   height: 200px;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: space-around;
   margin-top:-200px; 
   z-index: 1;
}

.qrCodeCont > svg{
    width:200px;
    height:200px;
}

.qrCodeCont:hover > svg{
    width:200px;
    height:200px;
    opacity:0.5;
}

.emptyAppText{
    font-size: 16px;
    font-family: Saira;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
}