.userm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  background-color: #f0f2f7;
  padding-top: 20px;
}

.userm-top-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
}

.userm-top-inner-container {
  display: flex;
  flex-direction: row;
}

.role-header-container {
  width: 25%;
  margin: 10px;
}

.user-header-container {
  width: 75%;
  margin: 10px;
}

.userm {
  display: flex;
  justify-content: space-between;
}

.userm-header {
  font-size: 32px;
  font-family: Saira;
  font-weight: normal;
  line-height: 40px;
}

.userm-role {
  width: 25%;
}

.userm button {
  cursor: pointer;
  height: 40px;
  padding: 0px 16px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  border-radius: 2px !important;
  border: none;
  color: #0062ff;
  background-color: white;
  margin-left: 10px;
}

.userm-data-container {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  width: 90%;
  border-radius: 8px;
  display: flex;
  height: 550px;
  overflow: hidden;
}
