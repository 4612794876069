.sectionTitle {
    font-weight: normal;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recents {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.folderCard {
    width: 20%;
    cursor: pointer;
    text-align: center;
    box-shadow: var(--shadowColor);
    padding: calc(var(--spacer) * 1px);
    min-height: calc(var(--spacer) * 18px);
    border-radius: calc(var(--spacer) * 1px);
    background-color: var(--backgroundColor);
    margin-right: calc(var(--spacer) * 1px);
}

.typeImage {
    width: calc(var(--spacer) * 7px);
    height: calc(var(--spacer) * 7px);
    margin: calc(var(--spacer) * 1px) 0px;
}

.files {
    width: 100%;
    margin: 0px;
    font-size: 13px;
    overflow: hidden;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    text-overflow: ellipsis;
    line-height: calc(var(--spacer) * 3px);
}

.storageCard {
    width: 25%;
    box-shadow: var(--shadowColor);
    min-height: calc(var(--spacer) * 18px);
    border-radius: calc(var(--spacer) * 1px);
    background-color: var(--backgroundColor);
    padding: calc(var(--spacer) * 1px) calc(var(--spacer) * 2px);
}

.storageCardText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.storageIndicator {
    background-color: #EEEEEE;
    height: calc(var(--spacer) * 0.5px);
    margin: calc(var(--spacer) * 2px) 0px;
    border-radius: calc(var(--spacer) * 1px);
}

.storageIndicatorActive {
    height: calc(var(--spacer) * 0.5px);
    background-color: var(--primaryColor);
}

.paths {
    display: flex;
    flex-direction: row;
}

.paths p {
    cursor: pointer;
    font-size: calc(var(--spacer) * 2.5px);
    margin: 0px calc(var(--spacer) * 0.5px);
}

.paths svg {
    cursor: pointer;
    width: calc(var(--spacer) * 2.5px);
}

.afFooter {
    bottom: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--spacer) * 9px);
    background-color: var(--backgroundColor);
    padding: 0px calc(var(--spacer) * 1.5px);
    width: calc(100% - (var(--spacer) * 6px));
    border-top: 1px solid var(--pageBackground);
}

.popupTitle {
    margin-top: calc(var(--spacer) * 2px);
}

.formArea {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: calc(var(--spacer) * 5px) 0px;
}

.column {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.note {
    color: #676767;
    font-size: calc(var(--spacer) * 1.5px);
}
.tabsInnerCon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }
.treePopUp {
    display: flex;
    overflow: scroll;
    border-radius: 3px;
    position: relative;
    flex-direction: column;
    background-color: white;
    padding: calc(var(--spacer) * 1.5px);
    max-height: 700px;
    min-height: 350px;
    width: 400px;
  }
.treePopupTitle {
    margin-top: calc(var(--spacer) * 2px);
    font-size: 20px;
}

.treeFormArea {
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
    margin: calc(var(--spacer) * 5px) 0px;
}
.treeFooter {
    bottom: 0px;
    display: flex;
    position: sticky;
    justify-content: flex-end;
    height: calc(var(--spacer) * 5px);
    background-color: var(--backgroundColor);
    border-top: 1px solid var(--pageBackground);
}
