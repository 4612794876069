$spacer: 8px;
/* color variables */
$theme-colors: (
  "blue60": #0062ff,
  "blue30":#97C1FF,
  "gray50" : #8C8C8C,
  "gray60":#676767,
  "green60":#24A148,
  "white":#ffffff,
  "notsocoolgray":#CDD4E4,
  "coolgray10":#F0F2F7,
  "black":#000000,
  "danger": #FF0000
);
/* variables */
$font-stack: 'Saira';
$font-weight: 400;
$spacer: 8px;
$grid-gutter-width: 24px !important;

/* functions */
@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

@function font-size($number) {
    @return $number;
}

/* mixins */
@mixin border($direction, $width, $color) {
    border-#{$direction}: $width solid $color;
}