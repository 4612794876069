.step-header {
  color: #000000;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin: 0;
}
.step-subheader {
  text-align: center;
}

.stepper-form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.stepper-form::-webkit-scrollbar {
  display: none;
}

.inner-stepper-form {
  min-width: 50%;
  max-width: 80%;
  padding-bottom: calc(var(--spacer) * 8px);
}

.form-elements {
  margin: 15px 0px;
}

.step-submit-container {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  padding-right: 10px;
  border-top: 2px solid #f0f2f7;
}


.afFooter {
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  height: calc(var(--spacer) * 9px);
  background-color: var(--backgroundColor);
  padding: 0px calc(var(--spacer) * 1.5px);
  width: calc(100% - (var(--spacer) * 6px));
  border-top: 1px solid var(--pageBackground);
}