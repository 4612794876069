/* Remove default bullets */
.root {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  line-height: 25px;
  flex-direction: row;
  justify-content: space-between;
}

.rootIcon {
  /* position: absolute;
  right: 10px; */
  color: lightgray;
}

ul {
  list-style-type:none;
}

/* Style the caret/arrow */
.root-active {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
  background-color: rgba(0, 0, 0, 0.08);
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
  transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.tree-active {
  display: block;
}
.caret.active::before {
  transform: rotate(90deg);
}
.span_dropdown{
  display: flex;
  align-items: center;
  width:75%;
  overflow: hidden;
}

.workspaceTable{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  cursor:pointer;
}