.assetsOuterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  background-color: #f0f2f7;
  padding-top: 40px;
}

.assetsContainer {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
  width: 84%;
  border-radius: 8px;
  display: flex;
  height: 550px;
  overflow: hidden;
}

.assetsInnerContainer {
  width: 100%;
  background-color: #f0f2f7;
  position: relative;
}

.assetsTableContainer {
  overflow-y: scroll;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  height: 501px;
}

.textAlign {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 8%;
}

.header {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  top: -100%;
  width: 66%;
}
.content {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  top: -36%;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 3%;
  margin-right: 4%;
  position: relative;
  top: 20%;
}
.btn {
  width: 50px !important;
  height: 40px;
  padding: 0px 0px 0px 10px;
}

.createContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 425px;
  width: 100%;
}
.firstColumn {
  display: flex;
  flex-direction: column;
  width: calc(var(--spacer) * 45px);
}
.errorMsg {
  position: absolute;
  top: 92%;
  right: 42%;
  color: #ff0a12;
  font-size: 13px;
  text-align: center;
  line-height: 24px;
}
.submitContainer {
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  padding-right: 10px;
  border-top: 2px solid #f0f2f7;
}

.container {
  width: 100%;
  height: 425px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.innerContainer {
  width: 400px;
  height: 300px;
  overflow: scroll;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
}
.editContainer {
  height: 472px;
  width: 100%;
  padding: 10px;
  overflow: auto;
}
.editField {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  border: none;
  overflow: auto;
  resize: none;
  font-size: 16px;
}
