.gridContainer {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
}

.inputStyle {
  width: 99% !important;
  border: none !important;
  z-index: 99 !important;
  background-color: inherit !important;
  text-indent: 5px !important;
  height: 46px !important;
  box-sizing: border-box !important;
  border-radius: 0px !important;
  outline: none;
}
