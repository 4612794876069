.mainWrapper {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 99999;
  justify-content: center;
  align-items: center;
}
.wrapper {
  position: relative;
  background-color: white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: 440px;
  height: 250px;
  border-radius: 3px;
}
.popupCloser {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  margin: 10px 16px 0px 0px;
  z-index: 99;
}
.close {
  width: 30px;
  cursor: pointer;
}
.textAlign {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /* left: 8%; */
}
.header {
  font-size: 18px !important;
  font-weight: 500;
  position: absolute;
  top: 8%;
  left: 6%;
  width: 400px;
  z-index: 98;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.content {
  font-size: 16px !important;
  max-width: calc(var(--spacer) * 46px); /*50px*/
  margin-top: 35px; /*15px*/
  height:120px;
  overflow:scroll;
  position:relative;
  left:8%;
  white-space:pre-line;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  position: absolute;
  bottom: 15px;
  right: 10px;
}

.primaryButton {
  height: 40px;
  min-width: 65px !important;
}

.secondaryButton {
  height: 40px;
  min-width: 65px !important;
}