.container {
  display: flex;
  padding: 0;
  justify-content: space-between;
  height: 100%;
}

.title{
    font-size: 32px;
    font-family: Saira;
    font-weight: normal;
    line-height: 40px;
}

.subtitle {
    color: #676767;
    font-size: 15px;
    font-family: Saira;
    font-weight: normal;
    line-height: 24px;
}
.main{
  padding-top: 20px;
  background-color: #f0f2f7;
  padding-left: 40px;
  min-height: 100vh;
}
.rolesLayoutMainContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
}

.textWrap{
  display: flex;
  align-items: center;
}

.passwordpolicyDesc{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    background-color: transparent;
    margin-right: 4px;
    margin-left: 4px;
}

.passwordpolicyDescVaried{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    background-color: transparent;
    margin-right: 4px;
    margin-left: 26px;
}

.rolesLayout {
  display: flex;
  padding: 24px;
  background-color: #ffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  margin-top: 10px;
  border-radius: 8px;
  margin-bottom: 32px;
  flex-direction: column;
  width:80%;
}

.oranizationLayout {
  display: flex;
  padding: 24px;
  background-color: #ffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  margin-top: 10px;
  border-radius: 8px;
  margin-bottom: 32px;
  flex-direction: column;
  width:60%;
}

.sessionLayout {
  display: flex;
  padding: 24px;
  background-color: #ffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  margin-top: 10px;
  border-radius: 8px;
  margin-bottom: 32px;
  flex-direction: column;
  width:30%;
}

.rolesLyoutTitle {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 0em;

}

.container {
  display: flex;
  flex-direction: row;
  padding: 20px, 5px;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 20px, 5px;
}

.subcontainer {
  display: flex;
  flex-direction: column;
  width:50%;
}

.fieldWrap {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.checkboxLabel {
  color: #000;
  padding: 0px;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  background-color: transparent;
}

.checkbox {
  cursor: pointer;
  margin-right: 10px;
}

.cancelButton {
  color: #0062ff;
  background-color: white;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  margin-left: 8px;
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 26px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
}

.saveButton {
  background-color: #0062ff;
  color: white;
  border: unset;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  min-width: 64px;
  box-shadow: none;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Saira, Roboto, Verdana;
  font-weight: normal;
  line-height: 1.75;
  padding-left: 26px;
  border-radius: 2px !important;
  padding-right: 16px;
  text-transform: none;
}

/* organisation css */

.textWrapOrganisation{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
/* 
Authentication css */

.authencticationLayout {
  display: flex;
  padding: 24px;
  background-color: #ffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  margin-top: 10px;
  border-radius: 8px;
  margin-bottom: 32px;
  flex-direction: column;
  max-width: 410px;
}