.submitContainer {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  padding-right: 10px;
  border-top: 2px solid #f0f2f7;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
}
.buttonContainer {
  height: 200px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: 1px solid lightgrey;
}
.buttonContainer::-webkit-scrollbar {
  display: none;
}
.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.input, .assignInput {
  box-sizing: border-box;
  height: var(--inputHeight);
  font-family: var(--fontName);
  font-size: var(--labelFontSize);
  background: var(--inputBackground);
  line-height: var(--labelLineHeight);
  border: 1px solid var(--inputBorderColor);
  width: 402px;
  text-indent: 5px;
  
}
.button {
  box-sizing: border-box;
  height: 40px;
  font-family: var(--fontName);
  font-size: var(--labelFontSize);
  line-height: var(--labelLineHeight);
  background-color: white;
  width: 400px;
  border: none;
  text-align: left;
}
.button:hover {
  background-color: lightgray;
}
.selected {
  box-sizing: border-box;
  height: var(--inputHeight);
  font-family: var(--fontName);
  font-size: var(--labelFontSize);
  line-height: var(--labelLineHeight);
  background-color: var(--primaryColor);
  width: 400px;
  border: none;
  border-top: 1px white solid;
  text-align: left;
  color: white;
}
.selected:hover {
  background-color: var(--primaryColor);
}
