.imgCont{
    width:fit-content;
    margin:48px auto;
}

.afModuleTitle{
    font-family: Saira;
    font-weight: normal;
    font-size: calc(var(--spacer) * 4px);
    line-height: calc(var(--spacer) * 5px);
    text-align: center;
    padding-top: 26px;
}