.afFooter {
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  height: calc(var(--spacer) * 9px);
  padding: 0px calc(var(--spacer) * 1.5px);
  width: calc(100% - (var(--spacer) * 6px));
  border-top: 1px solid var(--pageBackground);
}

.popupTitle {
  margin-top: calc(var(--spacer) * 2px);
}

.formArea {
  display: flex;
  justify-content: center;
  margin: calc(var(--spacer) * 5px) 0px;
  flex-direction: column;
  padding: 0 80px;
}

.column {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.finalHeader {
  font-size: 3rem;
  text-align: center;
}

.finalComp {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap:44px;
}

.finalButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.driveDetailHeading {
    width: 64px;
    height: 32px;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px !important;
    padding-bottom: 4px;
    list-style: none;
    color: #8c8c8c;
}

.label2 {
    width: 176px;
    height: 32px;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px !important;
    padding-bottom: 0;
}
.param{
  height: 4.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 14rem;
}